import React, { useContext } from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { Button, ButtonLink, Screen, ScreenHeader } from "@/components";
import { PrivacyModeContext } from "@/contexts";

import { useProfile } from "@moonscape/shared";
import { formatAmount, getCurrencySymbol } from "@moonscape/shared";
import clsx from "clsx";
import { Skeleton } from "antd";

import { usePositionsData } from "@moonscape/shared";

function formatPriceChange(priceChange) {
  if (isNaN(priceChange)) {
    return <span className="text-gray-400">-</span>;
  }

  const style = priceChange < 0 ? "text-red-500" : "text-green-500";
  const icon = priceChange < 0 ? "↓" : "↑";
  return (
    <span className={`${style} flex items-center`}>
      {icon}
      {Math.abs(priceChange).toFixed(2)}%
    </span>
  );
}

export const HoldingsTable = ({ showTopX, showTopXLoading }) => {
  const { privacyMode, setPrivacyMode } = useContext(PrivacyModeContext);

  const { isLoading, isIdle, isError, data } = usePositionsData();

  console.log("[cc] positions: ", data);

  // const { isLoading, isIdle, isError, data } = usePositionsData();
  const {
    isQueryLoading: isProfileQueryLoading,
    error: profileQueryError,
    profile,
  } = useProfile();

  const getTotalDisplay = () => {
    if (isLoading) return Array(showTopXLoading).fill(0);
    if (!data?.totals) return Array(showTopX).fill(0);
    const sortedTotals = Object.entries(data?.totals || {})
      .map(([asset, { amount, price, coingeckoId, price_change_24h }]) => ({
        asset,
        amount,
        price,
        coingeckoId,
        price_change_24h,
        totalValue: amount * price,
      }))
      .sort((a, b) => b.totalValue - a.totalValue);
    return sortedTotals;
  };

  const holdingFiatAmount = (coin) => {
    if (coin?.price) {
      return `${getCurrencySymbol(profile?.baseCurrency)} 
      ${formatAmount(coin.amount * coin.price)}`;
    } else {
      return "-";
    }
  };

  return (
    <Table>
      {/* <TableCaption>A list of your cryptocurrency balances.</TableCaption> */}
      <TableHeader>
        <TableRow>
          <TableHead className="flex-auto">Coin</TableHead>
          <TableHead className="flex-auto">Price</TableHead>
          <TableHead className="flex-auto">24h Change</TableHead>
          <TableHead className="flex-auto text-right">Holdings</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {getTotalDisplay()
          .slice(0, showTopX)
          .map((coin, index) => {
            return (
              <TableRow key={index}>
                {/* coin name and short code*/}
                <TableCell className="font-medium">
                  <div className="flex items-center">
                    <div className="flex flex-col gap-0">
                      <div className="text-sm">{coin.asset}</div>
                      <div className="text-xs text-gray-500">
                        {coin.coingeckoId
                          ?.split("-")
                          .map(
                            (word) =>
                              word?.charAt(0).toUpperCase() + word?.slice(1)
                          )
                          .join(" ")}
                      </div>
                    </div>
                  </div>
                </TableCell>
                {/* price */}
                <TableCell>
                  <div className="text-sm">
                    {coin?.price ? (
                      <>
                        {getCurrencySymbol(profile?.baseCurrency)}
                        {formatAmount(coin?.price)}
                      </>
                    ) : (
                      <span className="text-gray-400 text-xs">Unavailable</span>
                    )}
                  </div>
                </TableCell>
                {/* 24h change */}
                <TableCell>
                  {formatPriceChange(coin?.price_change_24h)}
                </TableCell>
                {/* holdings balance */}
                <TableCell className="text-right flex gap-1 items-end flex-col">
                  <div className={clsx("text-sm", { "blur-md": privacyMode })}>
                    {holdingFiatAmount(coin)}
                  </div>
                  <div
                    className={clsx("text-xs text-gray-500", {
                      "blur-md": privacyMode,
                    })}
                  >
                    {formatAmount(coin.amount)} {coin.asset}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        {!isProfileQueryLoading ? (
          !profile?.hasLinkExchangeAccounts ? (
            <TableRow>
              <TableCell colSpan={4} className="text-center">
                <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
                  {/* <p className="text-gray-500 text-base">
                    No linked exchange accounts
                  </p> */}

                  <ButtonLink
                    href="/settings/exchange"
                    buttonText="Link exchange"
                  />
                </div>
              </TableCell>
            </TableRow>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </TableBody>
    </Table>
  );
};
