import React from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

interface SlideProps {
  heading: string;
  description: React.ReactNode;
  helperText?: string;
  isFirstSlide?: boolean;
  onBack?: () => void;
  primaryButton: {
    text: string;
    onPress: () => void;
  };
  secondaryButton?: {
    text: string;
    onPress: () => void;
  };
}

export const Slide = ({
  heading,
  description,
  helperText,
  isFirstSlide,
  onBack,
  primaryButton,
  secondaryButton,
}: SlideProps) => {
  return (
    <div className="flex flex-col h-full px-12">
      {!isFirstSlide && onBack && (
        <button
          onClick={onBack}
          className="absolute top-4 left-6 text-gray-500 hover:text-gray-700 flex items-center"
        >
          <ChevronLeftIcon className="h-5 w-5 mr-1" />
          Back
        </button>
      )}

      <div className="mt-8">
        <h1 className="text-3xl font-semibold mb-10">{heading}</h1>
        <div className="text-xl justify-start text-gray-700 mb-6">
          {description}
        </div>
        {helperText && <p className="text-sm text-gray-500">{helperText}</p>}
      </div>

      <div className="mt-auto space-y-4 pb-8">
        <button
          onClick={primaryButton.onPress}
          className="w-full bg-green-500 text-white py-3 px-4 rounded-lg hover:bg-green-600"
        >
          {primaryButton.text}
        </button>
        <div className="h-[44px]">
          {secondaryButton && (
            <button
              onClick={secondaryButton.onPress}
              className="w-full text-gray-500 py-3 px-4 hover:text-gray-700"
            >
              {secondaryButton.text}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
