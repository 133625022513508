import { createMachine, assign } from "xstate";

interface OnboardingContext {
  taxJurisdiction: string | null;
  exchangeConnected: boolean;
  fromSettings: boolean;
  showTaxSettings: boolean;
  lastConnectedExchange?: string;
  postponedAt?: Date;
  postponedFrom?: string;
}

export const onboardingMachine = createMachine({
  id: "onboarding",
  initial: "welcome",
  context: {
    taxJurisdiction: null,
    exchangeConnected: false,
    fromSettings: false,
    showTaxSettings: false,
    lastConnectedExchange: null,
    postponedAt: null,
    postponedFrom: null,
  },
  states: {
    welcome: {
      on: {
        NEXT: {
          target: "taxCountry",
          actions: assign({
            showTaxSettings: true,
          }),
        },
        POSTPONE: {
          target: "postponed",
          actions: assign({
            postponedAt: () => new Date(),
            postponedFrom: "welcome",
          }),
        },
      },
    },
    taxCountry: {
      on: {
        BACK: { target: "welcome" },
        NEXT: { target: "exchange" },
      },
    },
    exchange: {
      on: {
        BACK: { target: "taxCountry" },
        SKIP: { target: "syncing" },
        POSTPONE: {
          target: "postponed",
          actions: assign({
            postponedAt: () => new Date(),
            postponedFrom: "exchange",
          }),
        },
        OPEN_CONNECT_EXCHANGE: {
          actions: "openConnectExchange",
        },
        EXCHANGE_CONNECTED: {
          actions: assign({
            exchangeConnected: true,
            lastConnectedExchange: ({ _, event }) => event?.data?.exchangeName,
            isCSV: ({ _, event }) => event?.data?.isCSV,
          }),
          target: "syncing",
        },
        NEXT: { target: "syncing" },
      },
    },
    syncing: {
      on: {
        BACK: { target: "exchange" },
        NEXT: { target: "help" },
        POSTPONE: {
          target: "postponed",
          actions: assign({
            postponedAt: () => new Date(),
            postponedFrom: "syncing",
          }),
        },
      },
    },
    help: {
      on: {
        BACK: { target: "syncing" },
        NEXT: { target: "completed" },
        POSTPONE: {
          target: "postponed",
          actions: assign({
            postponedAt: () => new Date(),
            postponedFrom: "help",
          }),
        },
      },
    },
    postponed: {
      type: "final",
      entry: ["onPostpone"],
    },
    completed: {
      type: "final",
      entry: ["completeOnboarding"],
    },
  },
});
