import React, { useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import {
  useGetLinkedExchangesQueryKey,
  useOnboardingActor,
} from "@moonscape/shared";
import { usePaginatedSearch, PAGINATED_API } from "@moonscape/shared";
import { Exchange } from "@moonscape/shared";
import { SelectExchangeScreen } from "./screens/SelectExchangeScreen";
import { ConnectionOptionsScreen } from "./screens/ConnectionOptionsScreen";
import { APIFormScreen } from "./screens/APIFormScreen";
import { CSVUploadScreen } from "./screens/CSVUploadScreen";
import { handleAPIKeySubmit } from "@moonscape/shared";
import { RegisterInterestScreen } from "./screens/RegisterInterestScreen";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ScreenWrapper } from "./ScreenWrapper";
import { useQueryClient } from "@tanstack/react-query";

type ConnectionStep =
  | "select"
  | "options"
  | "api-form"
  | "csv-upload"
  | "interest";

interface ExchangeConnectionModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ExchangeConnectionModal = ({
  isOpen,
  onClose,
}: ExchangeConnectionModalProps) => {
  const [step, setStep] = useState<ConnectionStep>("select");
  const [selectedExchange, setSelectedExchange] = useState<Exchange | null>(
    null
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState<Set<any>>(new Set());
  const actor = useOnboardingActor();
  const { send } = actor;

  const queryClient = useQueryClient();

  const handleExchangeSelect = (exchange: Exchange) => {
    setSelectedExchange(exchange);
    setStep("options");
  };

  const handleOptionSelect = (option: "api" | "csv" | "interest") => {
    switch (option) {
      case "api":
        if (selectedExchange?.api_active) {
          setStep("api-form");
        }
        break;
      case "csv":
        // allow to go to CSV to upload Moonscape format
        // if (selectedExchange?.csv_active) {
        setStep("csv-upload");
        // }
        break;
      case "interest":
        setStep("interest");
        break;
    }
  };

  const handleApiSubmit = async (values: any) => {
    try {
      await handleAPIKeySubmit({
        values: {
          ...values,
          exchange: selectedExchange?.id,
        },
        setSubmitting: () => {},
        setErrorMsgs,
        setError: () => {},
        errorMsgs,
        refetch: () => {},
        email: "",
        setAddKeySuccess: () => {},
        setIsSubmitting,
        onSuccess: () => {
          send({
            type: "EXCHANGE_CONNECTED",
            data: { exchangeName: selectedExchange?.exchangename },
          });
          queryClient.invalidateQueries({
            queryKey: [useGetLinkedExchangesQueryKey],
          });
          onClose();
        },
        onError: (error) => {
          console.error("Failed to save API keys:", error);
        },
      });
    } catch (error) {
      console.error("Failed to handle API key submission:", error);
    }
  };

  const handleCsvSubmit = async (file: File) => {
    try {
      await uploadExchangeCsv(file);
      send({
        type: "EXCHANGE_CONNECTED",
        data: { exchangeName: selectedExchange?.exchangename },
      });
      onClose();
    } catch (error) {
      console.error("Failed to upload CSV:", error);
      throw error;
    }
  };

  const renderScreen = () => {
    if (isSubmitting) {
      return (
        <div className="flex flex-col items-center justify-center h-full space-y-4">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-cyan-500" />
          <p className="text-gray-600 text-lg">
            Connecting to {selectedExchange?.exchangename}...
          </p>
        </div>
      );
    }

    switch (step) {
      case "select":
        return <SelectExchangeScreen onExchangeSelect={handleExchangeSelect} />;

      case "options":
        return (
          <ConnectionOptionsScreen
            exchange={selectedExchange!}
            onBack={() => setStep("select")}
            onSelectOption={handleOptionSelect}
          />
        );

      case "api-form":
        return (
          <APIFormScreen
            exchange={selectedExchange!}
            onBack={() => setStep("options")}
            onSubmit={handleApiSubmit}
          />
        );

      case "csv-upload":
        return (
          <CSVUploadScreen
            exchange={selectedExchange!}
            onBack={() => setStep("options")}
            onSubmit={handleCsvSubmit}
          />
        );

      case "interest":
        return (
          <RegisterInterestScreen
            exchange={selectedExchange!}
            onBack={() => setStep("options")}
          />
        );
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          if (!isSubmitting) {
            onClose();
            setStep("select");
            setSelectedExchange(null);
          }
        }}
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8">
              <div className="w-[800px] h-[600px]">
                <ScreenWrapper
                  heading="Import transactions from"
                  onClose={onClose}
                >
                  {renderScreen()}
                </ScreenWrapper>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
