import { axios } from "@/axios";
import { AxiosError } from "axios";
import { toast } from "@moonscape/shared";

export const handleAPIKeySubmit = async ({
  values,
  setSubmitting,
  setErrorMsgs,
  refetch,
  email,
  setAddKeySuccess,
  setError,
  errorMsgs,
  onSuccess,
  onError,
  setIsSubmitting,
}) => {
  setIsSubmitting(true);

  console.log("values: ", values);
  const { exchange, apiKey, apiSecret, apiPassword } = values;
  console.log("submitting formik", JSON.stringify(values, null, 2));
  // alert(JSON.stringify(values, null, 2));

  // reset the errorMsgs state to empty Set
  setErrorMsgs(new Set());

  /** flag to determine if settings page should trigger a sync on adding api
   * keys  */
  let shouldSync: boolean = false;

  // call axios post
  const data = {
    exchange: exchange,
    apiKey: apiKey?.trim(),
    apiSecret: apiSecret?.trim(),
    password: apiPassword?.trim(),
    userId: email,
  };
  console.log("apikeys data", data);
  try {
    const resp = await axios.post("/apikeys", data);
    // alert(JSON.stringify(resp, null, 2))
    console.log(
      "[onSubmit] JSON.stringify(resp, null, 2)",
      JSON.stringify(resp, null, 2)
    );

    // set success view if receive resp.result === OK
    if (resp?.data === "OK") {
      console.log("[api key added]");
      // refetch api keys to display up-to-date list in settings
      refetch();
      setAddKeySuccess(true);
      shouldSync = true;

      // toast.success("Exchange connected");

      setIsSubmitting(false);

      onSuccess();
    }
  } catch (axiosError: AxiosError) {
    console.log("error formik", axiosError?.response?.data);

    setError(true);
    // You could still use the add method since it returns the updated set
    if (axiosError?.response?.data?.message) {
      const newErrorMsgs = new Set(errorMsgs);
      newErrorMsgs.add(axiosError.response.data.message);
      setErrorMsgs(newErrorMsgs);
    }
    // alert(JSON.stringify(errorMsgs, null, 2))

    if (axiosError?.response?.status === 400) {
      console.log("axiosError", axiosError?.response?.data);
      // alert(axiosError.response.status)
      // TODO: remove this from production
      if (axiosError?.response?.data?.message) {
        const newErrorMsgs = new Set(errorMsgs);
        newErrorMsgs.add(axiosError.response.data.message);
        setErrorMsgs(newErrorMsgs);
      }
    }

    setIsSubmitting(false);
    onError(axiosError);
  }
  // formik - set to finish the form cycle
  setSubmitting(false);
  setIsSubmitting(false);
  // return shouldSync
  return { shouldSync };
};
