import React, { useContext } from "react";
import clsx from "clsx";

import { formatAmount } from "@moonscape/shared";
import { useCapitalGainsData } from "@moonscape/shared";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { PrivacyModeContext } from "@/contexts";
import { EmptyTaxState } from "@/components/EmptyTaxState";

dayjs.extend(relativeTime);

export const TaxSummary = ({ data }) => {
  const { cgt_report: cgt } = useCapitalGainsData({
    taxYear: data?.taxYear,
  });

  const { privacyMode } = useContext(PrivacyModeContext);
  const showHeading = data?.showHeading;

  // Check if there are any tax events
  const hasTaxEvents = cgt?.disposals > 0;

  if (!hasTaxEvents) {
    return <EmptyTaxState />;
  }

  const lastUpdatedTaxReport = dayjs(cgt?.updated_at).fromNow();
  const currency = "£";

  const summaryItems = [
    { color: "bg-blue-500", label: "Disposals", value: cgt?.disposals },
    {
      color: "bg-pink-500",
      label: "Proceeds",
      value: formatAmount(cgt?.total_proceeds, currency),
    },
    {
      color: "bg-purple-500",
      label: "CGT Allowance",
      value: formatAmount(cgt?.allowance, currency, true),
    },
    {
      color: "bg-green-500",
      label: "Estimated Gain",
      value: formatAmount(cgt?.total_gain, currency, true),
    },
    {
      color: "bg-red-500",
      label: "Estimated Loss",
      value: formatAmount(cgt?.total_loss, currency, true),
    },
    {
      color: "bg-yellow-500",
      label: "Estimated Tax",
      value: formatAmount(cgt?.estimated_tax_due, currency, true),
    },
  ];

  return (
    <div className="bg-white p-2 rounded-lg w-full mb-4">
      {showHeading && (
        <h2 className="text-lg font-medium text-gray-900 mb-4">Tax summary</h2>
      )}
      <div className="grid grid-cols-2 gap-y-4 text-sm">
        {summaryItems.map((item) => (
          <div className="flex space-x-2" key={item.label}>
            <span className={`w-3 h-3 ${item.color} rounded-full mt-1`}></span>
            <div>
              <div className="text-gray-500 font-medium">{item.label}</div>
              <div
                className={clsx("font-semibold text-lg", {
                  "text-gray-900": item.value,
                  "text-gray-400": !item.value,
                  "blur-md": privacyMode,
                })}
              >
                {item.value ? item.value : "-"}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
