import React, { useContext } from "react";
import clsx from "clsx";

import { PrivacyModeContext } from "@/contexts";

import { BrowserRouter as Router, Link } from "react-router-dom";
import {
  useProfile,
  formatCurrency,
  getCurrencySymbol,
} from "@moonscape/shared";

/** this should pull the user's tax rate and also other static information about them */
export const IndividualInformation = ({ data }) => {
  const { privacyMode, setPrivacyMode } = useContext(PrivacyModeContext);
  const { profile } = useProfile();
  const currencySymbol = getCurrencySymbol(profile?.baseCurrency);

  const generalInformationRows = [
    { label: "Tax jurisdiction", value: data?.taxJurisdiction },
    { label: "Current Income Tax Rate", value: data?.currentIncomeTaxRate },
    { label: "Current CGT Tax Rate", value: data?.currentCGTTaxRate },
    { label: "Base Currency", value: data?.baseCurrency },
    { label: "Accounting method", value: data?.costBasisMethod },
  ];

  const financialInformationRows = [
    { label: "Disposals", value: data?.disposals },
    {
      label: "Estimated Gain",
      value: formatCurrency(data?.estimateGain, currencySymbol),
    },
    {
      label: "Proceeds",
      value: formatCurrency(data?.proceeds, currencySymbol),
    },
    {
      label: "Estimated Loss",
      value: formatCurrency(data?.estimateLoss, currencySymbol),
    },
    {
      label: "CGT Allowance",
      value: formatCurrency(data?.allowance, currencySymbol),
    },
    {
      label: "Estimated Tax",
      value: formatCurrency(data?.taxableGain, currencySymbol),
    },
  ];

  return (
    <div className="bg-white p-2 rounded-lg w-full mb-4">
      <h2 className="text-lg font-medium text-gray-900">
        Individual information
      </h2>
      <p className="text-sm text-gray-500 mb-4">
        You can change these information in <br />
        <Link to="/settings/tax" className=" text-green-600 hyperlink ">
          Tax Settings
        </Link>
      </p>

      {/* General Information Section */}
      <div className="mb-4">
        <h3 className="text-md font-semibold text-gray-900 mb-2">
          General Information
        </h3>
        {generalInformationRows.map((row, index) => (
          <div
            key={index}
            className="hover:bg-gray-100 transition duration-150 ease-in-out py-1 flex justify-between"
          >
            <div className="text-sm font-medium text-gray-600">{row.label}</div>
            <div className="text-sm text-gray-900">{row.value}</div>
          </div>
        ))}
      </div>

      {/* Financial Information Section */}
      <div>
        <h3 className="text-md font-semibold text-gray-900 mb-2">
          Financial Information
        </h3>
        {financialInformationRows.map((row, index) => (
          <div
            key={index}
            className="hover:bg-gray-100 transition duration-150 ease-in-out py-1 flex justify-between"
          >
            <div className="text-sm font-medium text-gray-600">{row.label}</div>
            <div
              className={clsx("text-sm text-gray-900", {
                "blur-md": privacyMode,
              })}
            >
              {row.value}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
