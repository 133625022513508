import numbro from "numbro";

export const cryptoMantissa = 5;
export const fiatMantissa = 2;

export const formattedAmount = (txn, ccy, isFiatCurrency = false) => {
  const { side, buyqty, sellqty } = txn;

  // Determine effectiveAmount based on side and availability of buyqty and sellqty
  let effectiveAmount;
  let counterAmount;
  if (side === "buy") {
    effectiveAmount = buyqty ?? sellqty; // Use buyqty if available, otherwise fallback to sellqty
    counterAmount = sellqty ?? buyqty; // Inverse of effectiveAmount
  } else if (side === "sell") {
    effectiveAmount = sellqty ?? buyqty; // Use sellqty if available, otherwise fallback to buyqty
    counterAmount = buyqty ?? sellqty; // Inverse of effectiveAmount
  } else {
    // Handle other cases or throw an error
    throw new Error("Invalid transaction side");
  }

  console.log(
    "Effective Amount:",
    effectiveAmount,
    "Counter Amount:",
    counterAmount
  );

  const parsedAmount = parseFloat(effectiveAmount);
  if (Number.isNaN(parsedAmount)) {
    return effectiveAmount; // Return the original input if it can't be parsed to a number
  }

  let numberFormat;
  if (isFiatCurrency) {
    numberFormat = numbro(parsedAmount).format({
      thousandSeparated: true,
      mantissa: fiatMantissa, // For fiat currencies, always use 2 decimal places
    });
  } else {
    numberFormat = numbro(parsedAmount).format({
      thousandSeparated: true,
      mantissa: parsedAmount < 1 ? 8 : 2, // Use 8 decimal places for small amounts, otherwise 2
    });
  }

  return numberFormat;
};

export const formatPrice = (price) => {
  const numberFormat =
    price < 1
      ? numbro(price).format({
          trimMantissa: false,
          mantissa: cryptoMantissa,
        })
      : numbro(price).format({
          trimMantissa: false,
          mantissa: fiatMantissa,
        });

  return numberFormat;
};

/** format amounts 0.00 and trunc 0.10000000 to 0.1 BTC e.g. */
export const formatAmount = (
  amount,
  ccy = undefined,
  isFiatCurrency = false
) => {
  // early return if amount is null or undefined
  // Check if numberFormat is null or undefined and return "-" in that case
  if (amount == null) {
    return "-";
  }
  // Remove any "+" or "-" prefixes
  const sanitizedAmount = String(amount).replace(/^[-+]/, "");

  const parsedAmount = parseFloat(sanitizedAmount);
  if (Number.isNaN(parsedAmount)) {
    return sanitizedAmount;
  }

  const absAmount = Math.abs(amount);

  let numberFormat;
  if (isFiatCurrency) {
    numberFormat = numbro(parsedAmount).format({
      thousandSeparated: true,
      mantissa: fiatMantissa, // For fiat currencies, always use 2 decimal places
    });
  } else {
    numberFormat =
      absAmount < 1
        ? numbro(parsedAmount).format({
            trimMantissa: true,
            mantissa: cryptoMantissa,
          })
        : numbro(parsedAmount).format({
            thousandSeparated: true,
            trimMantissa: false, // Set trimMantissa to false
            mantissa: fiatMantissa,
          });
  }

  return ccy ? `${ccy} ${numberFormat}` : numberFormat;
};

export const formatInteger = (amount, ccy) => {
  const parsedAmount = parseFloat(amount);
  if (Number.isNaN(parsedAmount)) {
    return amount;
  }
  const numberFormat = numbro(parsedAmount).format({
    trimMantissa: true,
    mantissa: 0,
  });

  return numberFormat;
};

/** Convert currency code to symbol */
export const getCurrencySymbol = (currencyCode: string): string => {
  switch (currencyCode?.toUpperCase()) {
    case "USD":
      return "$";
    case "GBP":
      return "£";
    case "EUR":
      return "€";
    case "SGD":
      return "S$";
    case "AUD":
      return "A$";
    case "CAD":
      return "C$";
    case "CHF":
      return "Fr";
    case "JPY":
      return "¥";
    case "KRW":
      return "₩";
    case "HKD":
      return "HK$";
    case "AED":
      return "د.إ";
    case "NZD":
      return "NZ$";
    case "SEK":
      return "kr";
    default:
      return currencyCode; // Return the currency code if no symbol found
  }
};

/** format amounts 0.00 and trunc 0.10000000 to 0.1 BTC e.g. */
export const formatCurrency = (amount, ccy = "£") => {
  const parsedAmount = parseFloat(amount);
  if (Number.isNaN(parsedAmount)) {
    return amount;
  }

  const absAmount = Math.abs(parsedAmount);

  const numberFormat =
    absAmount < 1 && absAmount > 0
      ? numbro(parsedAmount).format({
          trimMantissa: true,
          mantissa: cryptoMantissa,
        })
      : numbro(parsedAmount).format({
          thousandSeparated: true,
          trimMantissa: true,
          mantissa: fiatMantissa,
        });

  return `${ccy} ${numberFormat}`;
};

export const formatCurrencySummary = (amount, ccy = "£") => {
  const parsedAmount = parseFloat(amount);
  if (Number.isNaN(parsedAmount)) {
    return amount;
  }
  const abs_amount = Math.abs(parseInt(amount));
  let numberFormat;
  if (abs_amount >= 1e12) {
    numberFormat = (abs_amount / 1e12).toFixed(2) + "t";
  } else if (abs_amount >= 1e9) {
    numberFormat = (abs_amount / 1e9).toFixed(2) + "b";
  } else if (abs_amount >= 1e6) {
    numberFormat = (abs_amount / 1e6).toFixed(2) + "m";
  } else if (abs_amount >= 1e3) {
    numberFormat = numberFormat = (abs_amount / 1e3).toFixed(2) + "k";
  } else if (abs_amount >= 1e2) {
    numberFormat = numbro(parsedAmount).format({
      thousandSeparated: true,
      trimMantissa: true,
      mantissa: 1,
    });
  } else if (abs_amount >= 10) {
    numberFormat = numbro(parsedAmount).format({
      thousandSeparated: true,
      trimMantissa: true,
      mantissa: fiatMantissa,
    });
  } else if (abs_amount > 1)
    numberFormat = numbro(parsedAmount).format({
      trimMantissa: true,
      mantissa: 3,
    });
  else
    numberFormat = numbro(parsedAmount).format({
      trimMantissa: true,
      mantissa: 3,
    });
  return `${ccy} ${numberFormat}`;
};

export const formatNumberNanToZero = (value) => {
  return value | 0;
};
