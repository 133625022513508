import { SelectionComponent } from "../SelectionComponent";
import { useProfile } from "@moonscape/shared";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useState, useEffect } from "react";

export const TaxJurisdictionSelect = ({
  onSuccess,
  handleTaxJurisdictionChange,
  selectedValue,
}: {
  onSuccess?: () => void;
  selectedValue?: string;
  handleTaxJurisdictionChange?: (value: string) => void;
}) => {
  const { profile, setProfileTax } = useProfile();

  console.log("[cc] mutation profile", profile);

  const taxJurisdictionOptions = [
    { value: "United States", label: "United States 🇺🇸" },
    { value: "United Kingdom", label: "United Kingdom 🇬🇧" },
    { value: "Australia", label: "Australia 🇦🇺" },
    { value: "Canada", label: "Canada 🇨🇦" },
    { value: "France", label: "France 🇫🇷" },
    { value: "Germany", label: "Germany 🇩🇪" },
    { value: "Hong Kong", label: "Hong Kong 🇭🇰" },
    { value: "Ireland", label: "Ireland 🇮🇪" },
    { value: "Italy", label: "Italy 🇮🇹" },
    { value: "Japan", label: "Japan 🇯🇵" },
    { value: "Malta", label: "Malta 🇲🇹" },
    { value: "Netherlands", label: "Netherlands 🇳🇱" },
    { value: "New Zealand", label: "New Zealand 🇳🇿" },
    { value: "Portugal", label: "Portugal 🇵🇹" },
    { value: "Singapore", label: "Singapore 🇸🇬" },
    { value: "South Korea", label: "South Korea 🇰🇷" },
    { value: "Spain", label: "Spain 🇪🇸" },
    { value: "Sweden", label: "Sweden 🇸🇪" },
    { value: "Switzerland", label: "Switzerland 🇨🇭" },
    { value: "United Arab Emirates", label: "United Arab Emirates 🇦🇪" },
  ];

  console.log("[tax] selectedValue: ", selectedValue);

  return (
    <div>
      <SelectionComponent
        options={taxJurisdictionOptions}
        selectedValue={selectedValue}
        handleSelect={(event) => {
          let value = event.target.value;

          // handle select from props also sets the state
          handleTaxJurisdictionChange && handleTaxJurisdictionChange(value);

          const updatedProfileTax = {
            tax_jurisdiction: value,
          };

          setProfileTax(updatedProfileTax);
        }}
      />
    </div>
  );
};
