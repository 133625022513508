export const ONBOARDING_EVENTS = {
  // Step transitions
  STEP_TRANSITION: "onboarding_step_transition",
  STEP_SKIP: "onboarding_step_skip",

  // Tax settings actions
  TAX_COUNTRY_SELECTED: "tax_country_selected",
  TAX_SETTINGS_UPDATED: "tax_settings_updated",

  // User attributes
  USER_ATTRIBUTES_UPDATED: "user_attributes_updated",

  // Exchange actions
  EXCHANGE_SELECTED: "exchange_selected",
  EXCHANGE_SEARCH: "exchange_search",
  EXCHANGE_CONNECTION_METHOD_SELECTED: "exchange_connection_method_selected",
  EXCHANGE_API_CONNECTED: "exchange_api_connected",
  EXCHANGE_CSV_UPLOADED: "exchange_csv_uploaded",
  EXCHANGE_INTEREST_REGISTERED: "exchange_interest_registered",
} as const;

export const SETTINGS_EVENTS = {
  // Tax settings actions
  TAX_SETTINGS_OPENED: "tax_settings_opened",
  TAX_SETTINGS_UPDATED: "tax_settings_updated",

  // Exchange actions
  EXCHANGE_MODAL_OPENED: "exchange_modal_opened",
  EXCHANGE_SELECTED: "exchange_selected",
  EXCHANGE_SEARCH: "exchange_search",
  EXCHANGE_CONNECTION_METHOD_SELECTED: "exchange_connection_method_selected",
  EXCHANGE_API_CONNECTED: "exchange_api_connected",
  EXCHANGE_CSV_UPLOADED: "exchange_csv_uploaded",
  EXCHANGE_INTEREST_REGISTERED: "exchange_interest_registered",
} as const;

export const EVENT_CATEGORIES = {
  ONBOARDING: "onboarding",
  SETTINGS: "settings",
} as const;
