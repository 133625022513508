import React, { InputHTMLAttributes } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

interface SearchBarProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

export const SearchBar = ({ className, ...props }: SearchBarProps) => {
  return (
    <div className={classNames("relative", className)}>
      <div
        className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
        aria-hidden="true"
      >
        <MagnifyingGlassIcon
          className="h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </div>
      <input
        type="search"
        className={classNames(
          "block w-full pl-10 pr-3 py-4 text-lg",
          "border border-gray-300 rounded-md",
          "bg-white text-gray-900 placeholder-gray-500",
          "focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500",
          "sm:text-sm"
        )}
        {...props}
      />
    </div>
  );
};
