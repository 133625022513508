import { useState, useCallback, useEffect, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "@moonscape/shared";
import { authorizedAxios } from "@/axios";

/** abstracted hook for paginated search endpoints */
export const usePaginatedSearch = (
  endpoint: string,
  initialQuery = "",
  limit = 20,
  onLoading?: (loading: boolean) => void
) => {
  const [searchQuery, setSearchQuery] = useState(initialQuery);
  const [cursor, setCursor] = useState<string | null>(null);
  const [allItems, setAllItems] = useState<any[]>([]);
  const { getToken, user } = useAuth();
  const email = user?.email;

  const fetchData = async () => {
    console.log("Fetching data for cursor:", cursor);
    const token = await getToken();
    const res = await authorizedAxios(token).get(endpoint, {
      params: {
        search: searchQuery,
        cursor: cursor || 0,
        limit,
      },
    });
    return res.data;
  };

  const { data, isLoading, isError, error, isFetching, refetch } = useQuery({
    queryKey: [`${endpoint}-search`, searchQuery, cursor],
    queryFn: fetchData,
    enabled: !!email,
  });
  // Clear data when search changes
  useEffect(() => {
    setAllItems([]); // Clear existing items
    setCursor(null); // Reset cursor
    if (data?.items) {
      setAllItems(data.items);
    }
  }, [searchQuery]); // Only depend on searchQuery

  const handleSearch = useCallback((query: string) => {
    setAllItems([]); // Clear items immediately on search
    setSearchQuery(query);
    setCursor(null);
  }, []);

  // Update items when new data arrives
  useEffect(() => {
    if (data?.items) {
      if (!cursor) {
        setAllItems(data.items);
      } else {
        setAllItems((prev) => [...prev, ...data.items]);
      }
    }
  }, [data?.items, cursor]);
  onLoading?.(isLoading || isFetching);

  const fetchNextPage = useCallback(() => {
    if (data?.remaining > 0 && data?.cursor) {
      setCursor(data.cursor);
    }
  }, [data?.remaining, data?.cursor]);

  const resetSearch = useCallback(() => {
    setCursor(0);
    setSearchQuery("");

    refetch();
  }, [refetch]);

  const setSearchQueryAndReset = useCallback(
    (query: string) => {
      setSearchQuery(query);
      if (!query) {
        resetSearch();
      }
    },
    [resetSearch, searchQuery]
  );

  return {
    data: allItems,
    searchQuery,
    setSearchQuery: handleSearch,
    hasMore: data?.remaining > 0,
    fetchNextPage: () => {
      if (data?.remaining > 0 && data?.cursor) {
        setCursor(data.cursor);
      }
    },
    isLoading,
    isFetchingNextPage: isFetching && !!cursor,
    isError,
    error,
    resetSearch: () => {
      setAllItems([]); // Clear items on reset
      setCursor(null);
      setSearchQuery("");
      refetch();
    },
  };
};
