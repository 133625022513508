import React, { useContext } from "react";

import { OnboardingModal, TradesScreen } from "@/screens";

import { useProfile } from "@moonscape/shared";
import { Loading } from "@/components/Loading";

export const LandingScreen = () => {
  const { profile, isQueryLoading } = useProfile();
  const isOnboardingCompleted = profile?.attributes?.onboarding_completed;

  if (isQueryLoading) {
    return <Loading />;
  }

  return (
    <>
      <TradesScreen />
      {!isOnboardingCompleted && <OnboardingModal />}
    </>
  );
};
