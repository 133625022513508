import { TaxSettingKey } from "./TaxOptionsConfig";
import { taxSettingsOptions } from "./TaxOptionsConfig";
import { TaxSelect } from "./TaxSelect";
import React from "react";

export const TaxAccountingMethodSelect = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const key = TaxSettingKey.AccountingMethod;
  return (
    <TaxSelect
      taxSettingKey={key}
      options={taxSettingsOptions[key]}
      onSuccess={onSuccess}
    />
  );
};
