import React, { useState } from "react";
import { Slide } from "./Slide";
import { useOnboardingActor } from "@moonscape/shared";
import { useSelector } from "@xstate/react";
import { ExchangeConnectionModal } from "../ExchangeConnectionModal";

export const ExchangeScreen = () => {
  const actor = useOnboardingActor();
  const { send } = actor;
  const { exchangeConnected, lastConnectedExchange } = useSelector(
    actor,
    (state) => state.context
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const description = (
    <div className="space-y-4">
      {exchangeConnected ? (
        <div className="space-y-2 text-center">
          <p className="text-lg text-green-500">
            ✓ {lastConnectedExchange} connected successfully
          </p>
          <p className="text-sm text-gray-500">
            You can connect more exchanges anytime from the settings page
          </p>
        </div>
      ) : (
        <p className="text-xl text-center text-gray-700">
          Search for your exchange or wallet <br /> And connect to start
          tracking your portfolio.
        </p>
      )}
    </div>
  );

  return (
    <>
      <Slide
        heading="Where do you want to start importing from?"
        description={description}
        onBack={() => send({ type: "BACK" })}
        primaryButton={{
          text: exchangeConnected ? "Continue" : "Import transactions",
          onPress: () => {
            if (exchangeConnected) {
              send({ type: "NEXT" });
            } else {
              setIsModalOpen(true);
            }
          },
        }}
        secondaryButton={{
          text: "Skip for now",
          onPress: () => send({ type: "SKIP" }),
        }}
      />

      <ExchangeConnectionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};
