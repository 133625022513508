import React, { useState, useEffect } from "react";
import { useAPIKeysData, useProfile, useAuth } from "@moonscape/shared";
import { LinkedExchangeList } from "./ConnectedExchangeList";
import { useSyncJobStatus } from "@/hooks/useSyncJobStatus";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useRouteMatch, useLocation } from "react-router-dom";
import { ExchangeConnectionModal } from "@/screens/ExchangeConnectionModal";

dayjs.extend(relativeTime);

export const ExchangeAPISettingsNew = () => {
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();

  // Check if we should show the modal based on the URL
  useEffect(() => {
    if (location.pathname.endsWith("/new")) {
      setShowModal(true);
    }
  }, [location]);

  const { user, isAuthenticated, getToken } = useAuth();
  const { path, url } = useRouteMatch();

  // get a list of linked exchange connections
  const {
    isQueryLoading,
    error: queryError,
    apikeys,
    email,
    linkedExchanges,
  } = useAPIKeysData();

  const {
    isQueryLoading: isProfileQueryLoading,
    error: profileQueryError,
    profile,
  } = useProfile({ refetchOnWindowFocus: true });

  return (
    <div>
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Exchange connections
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            Add API keys to enable syncing of your transaction history from your
            exchanges
          </p>
        </div>
        <div className="mt-6">
          <dl className="divide-y divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">
                Connected exchanges
              </dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">
                  <LinkedExchangeList exchanges={linkedExchanges} />
                </span>
                <span className="ml-4 flex-shrink-0 ">
                  <button
                    type="button"
                    className="rounded-full bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => setShowModal(true)}
                  >
                    Connect exchange
                  </button>
                </span>
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <ExchangeConnectionModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};
