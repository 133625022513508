import React, { useState } from "react";
import { Exchange } from "@moonscape/shared";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

interface RegisterInterestScreenProps {
  exchange: Exchange;
  onBack: () => void;
}

export const RegisterInterestScreen = ({
  exchange,
  onBack,
}: RegisterInterestScreenProps) => {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Here you would call your API to register interest
      await registerInterest({ exchange: exchange.id, email });
      setIsSubmitted(true);
    } catch (error) {
      console.error("Failed to register interest:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-3 mb-6">
        <button onClick={onBack} className="text-gray-500 hover:text-gray-700">
          <ArrowLeftIcon className="h-5 w-5" />
        </button>
        <div className="flex items-center">
          <img
            src={exchange?.thumbnail_url}
            alt={exchange?.name}
            className="w-8 h-8 object-contain mr-2"
          />
          <h3 className="text-lg font-medium">
            Register Interest in {exchange?.exchangename}
          </h3>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center h-[400px] space-y-6">
        {isSubmitted ? (
          <div className="text-center space-y-4">
            <div className="text-green-500 text-xl">✓</div>
            <p className="text-lg text-gray-900">Thanks for your interest!</p>
            <p className="text-sm text-gray-500">
              We'll notify you at {email} when {exchange.exchangename}{" "}
              integration becomes available.
            </p>
            <button
              onClick={onBack}
              className="mt-4 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Back to Options
            </button>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="w-full max-w-md space-y-6">
            <div className="text-center space-y-2">
              <p className="text-lg text-gray-900">
                Get notified when {exchange.exchangename} becomes available
              </p>
              <p className="text-sm text-gray-500">
                We'll let you know as soon as we add support for this exchange.
              </p>
            </div>

            <div className="space-y-2">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500"
              />
            </div>

            <button
              type="submit"
              disabled={isSubmitting || !email}
              className={`
                w-full px-4 py-2 text-sm font-medium text-white rounded-md
                ${
                  isSubmitting || !email
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-cyan-600 hover:bg-cyan-700"
                }
              `}
            >
              {isSubmitting ? "Submitting..." : "Notify Me"}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};
