import {
  UseQueryResult,
  useQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { authorizedAxios } from "@/axios";
import { useAuth, toast, useOnboardingActor } from "@moonscape/shared";

import { minutes } from "@/utils";

interface UserAttributes {
  onboarding_completed?: boolean;
  selected_theme?: "light" | "dark";
  tax_jurisdiction_selected?: boolean;
  exchange_connected?: boolean;
  last_active_at?: string;
  [key: string]: any; // Allow for additional attributes
}

interface Profile {
  // ... existing profile fields ...
  attributes?: UserAttributes;
}

interface ProfileOptions {
  refetchOnWindowFocus?: boolean;
}

export const useProfileQueryKey = ["profile"];

export const useProfile = (options?: ProfileOptions) => {
  const { user, isAuthenticated, isLoading, getToken } = useAuth();
  const email = user?.email;
  const queryClient = useQueryClient();

  // order matters here bc of how the providers are set up in which order

  // Try to get onboarding actor - will return null if outside onboarding context
  // try {
  const { send } = useOnboardingActor();

  const getProfile = async (): Promise<Profile> => {
    const url = `/profile`;
    try {
      const token = await getToken();
      const res = await authorizedAxios(token).get(url);
      return res.data;
    } catch (error) {
      console.log("[useProfile] error", error);
      throw error;
    }
  };

  const {
    isLoading: isQueryLoading,
    isIdle,
    isSuccess,
    error,
    isError,
    data: profile,
    refetch,
  }: UseQueryResult<Profile, unknown> = useQuery({
    queryKey: [useProfileQueryKey],
    queryFn: getProfile,
    enabled: !!email,
    refetchOnWindowFocus: !!options?.refetchOnWindowFocus,
    staleTime: 1 * minutes,
    refetchInterval: 5 * minutes,
  });

  const updateProfileTax = async (updatedProfile) => {
    const token = await getToken();
    const url = `/profile/tax`;

    const response = await authorizedAxios(token).post(url, updatedProfile);

    if (!response.data) {
      throw new Error("Profile update failed");
    }

    return response.data;
  };

  const mutation = useMutation({
    mutationFn: updateProfileTax,
    onMutate: async (newTaxSettings) => {
      console.log("[cc] mutation newTaxSettings", newTaxSettings);
      await queryClient.cancelQueries({ queryKey: ["profile"] });

      const previousProfile = queryClient.getQueryData(["profile"]);

      // Create the optimistic update
      try {
        const optimisticProfile = {
          ...previousProfile,
          taxJurisdiction:
            newTaxSettings?.tax_jurisdiction ||
            previousProfile?.taxJurisdiction,
          taxFilingStatus:
            newTaxSettings?.tax_filing_status ||
            previousProfile?.taxFilingStatus,
          accountingMethod:
            newTaxSettings?.accounting_method ||
            previousProfile?.accountingMethod,

          // don't optimistic update tax rates
          taxRates: previousProfile?.taxRates,
        };

        console.log("[cc] mutation optimisticProfile", optimisticProfile);

        // Set the optimistic data
        queryClient.setQueryData(["profile"], optimisticProfile);

        // Return both previous and optimistic values in context
        return { previousProfile, optimisticProfile };
      } catch (error) {
        console.log("[cc] mutation error", error);
      }
    },

    onError: (err, newTodo, context) => {
      queryClient.setQueryData(["profile"], context.previousProfile);
      toast.error("Error updating profile. Please try again.");
    },

    onSuccess: () => {
      // toast.success("Updated tax settings");
    },
    onSettled: () => {
      queryClient.invalidateQueries(["profile"]);
    },
  });

  const setProfileTax = ({
    tax_jurisdiction,
    tax_filing_status,
    accounting_method,
    cgt_rate,
  }: {
    tax_jurisdiction?: string;
    tax_filing_status?: string;
    accounting_method?: string;
    cgt_rate?: number;
  }) => {
    mutation.mutate({
      tax_jurisdiction,
      tax_filing_status,
      accounting_method,
      cgt_rate,
    });
  };

  const updateUserAttributes = async (attributes: Record<string, any>) => {
    const token = await getToken();
    const url = `/profile/attributes`;

    const response = await authorizedAxios(token).post(url, attributes);

    if (!response.data) {
      throw new Error("User attributes update failed");
    }

    return response.data;
  };

  const attributesMutation = useMutation({
    mutationFn: updateUserAttributes,
    onMutate: async (newAttributes) => {
      await queryClient.cancelQueries({ queryKey: ["profile"] });
      const previousProfile = queryClient.getQueryData(["profile"]);

      const optimisticProfile = {
        ...previousProfile,
        attributes: {
          ...previousProfile?.attributes,
          ...newAttributes,
        },
      };

      queryClient.setQueryData(["profile"], optimisticProfile);
      return { previousProfile, optimisticProfile };
    },
    onError: (err, newAttributes, context) => {
      queryClient.setQueryData(["profile"], context.previousProfile);
      toast.error("Error updating user attributes. Please try again.");
    },
    onSettled: () => {
      queryClient.invalidateQueries(["profile"]);
    },
  });

  const setUserAttributes = (attributes: Partial<UserAttributes>) => {
    attributesMutation.mutate(attributes);
  };

  return {
    isQueryLoading,
    isIdle,
    error,
    profile,
    email,
    refetch,
    mutation,
    setProfileTax,
    setUserAttributes,
    attributesMutation,
  };
};
