import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { PrivacyModeContext } from "@/contexts";
import clsx from "clsx";
import {
  ArrowsRightLeftIcon,
  ReceiptPercentIcon,
} from "@heroicons/react/24/outline";
import { TransactionListContext } from "@/contexts";
import { Skeleton } from "antd";
import { AuditTradeItemClass } from "@moonscape/shared";

export const TradeItemMedium = ({ t, isQueryLoading }) => {
  const history = useHistory();
  const { setModalOpen, setExtnTxnId } = useContext(TransactionListContext);
  const { privacyMode } = useContext(PrivacyModeContext);

  const handleRowClick = () => {
    setExtnTxnId(t.ext_txn_id);
    setModalOpen(true);
  };

  const tradeItem = new AuditTradeItemClass(t);
  const displayData = tradeItem.getDisplayData();

  return (
    <tr
      key={displayData.transaction}
      className="hover:bg-gray-100"
      onClick={handleRowClick}
    >
      {/* tradeid */}
      <td className="px-2 py-4 whitespace-nowrap text-left">
        <div className="flex items-center">
          <div className="px-4">
            <div className="text-sm text-gray-500 align-middle flex flex-col justify-center">
              {isQueryLoading ? (
                <Skeleton.Button active={true} size={"small"} shape={"round"} />
              ) : (
                <div className="flex flex-col justify-center">
                  <p className="text-center">{displayData?.exchangeName}</p>
                  <p className="text-center text-gray-400 text-xs pt-1">
                    {displayData?.transaction}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </td>
      {/* type */}
      <td className="py-4 whitespace-nowrap text-center lg:px-6">
        <div className="text-sm font-medium text-gray-700">
          {isQueryLoading ? (
            <Skeleton.Button active={true} size={"small"} shape={"round"} />
          ) : (
            displayData.type
          )}
        </div>
        {/* <div className="text-sm text-gray-500">{displayData.transaction}</div> */}
      </td>
      {/* side */}
      <td className="py-4 whitespace-nowrap lg:px-6">
        <span
          className={`px-2 inline-flex  text-xs leading-5 font-semibold rounded-full bg-${tradeItem.getSideColor()}-100 text-${tradeItem.getSideColor()}-800`}
        >
          {isQueryLoading ? (
            <Skeleton.Button active={true} size={"small"} shape={"round"} />
          ) : (
            <>
              {/* {displayData.side} */}
              {tradeItem.isTransferType() ? (
                <div className="flex-shrink-0 align-middle ">
                  <ArrowsRightLeftIcon
                    className={`h-5 w-5 text-${tradeItem.getTransferColor()}-400`}
                    aria-hidden="true"
                  />
                </div>
              ) : null}
              {tradeItem.isInterestType() ? (
                <div className="flex-shrink-0 align-middle ">
                  <ReceiptPercentIcon
                    className={`h-5 w-5 text-${tradeItem.getTransferColor()}-400`}
                    aria-hidden="true"
                  />
                </div>
              ) : null}
            </>
          )}
        </span>
      </td>
      {/* ccy1 buy asset */}
      <td className="px-6 py-4 whitespace-nowrap text-center">
        <div className="text-sm text-gray-900">
          {isQueryLoading ? (
            <Skeleton.Button active={true} size={"small"} shape={"round"} />
          ) : (
            displayData.buyAsset
          )}
        </div>
      </td>
      {/* ccy 2 sell asset */}
      <td className="px-6 py-4 whitespace-nowrap text-center">
        <div className="text-sm text-gray-900">
          {isQueryLoading ? (
            <Skeleton.Button active={true} size={"small"} shape={"round"} />
          ) : (
            displayData.sellAsset
          )}
        </div>
      </td>
      {/* Buy Amount */}
      <td className="px-6 py-4 whitespace-nowrap text-center">
        <div
          className={clsx("text-sm text-gray-900", { "blur-md": privacyMode })}
        >
          {isQueryLoading ? (
            <Skeleton.Button active={true} size={"small"} shape={"round"} />
          ) : (
            displayData.formattedBuyAmount
          )}
        </div>
      </td>
      {/* Sell Amount 2 */}
      <td className="px-6 py-4 whitespace-nowrap text-center">
        <div
          className={clsx("text-sm text-gray-900", { "blur-md": privacyMode })}
        >
          {isQueryLoading ? (
            <Skeleton.Button active={true} size={"small"} shape={"round"} />
          ) : (
            displayData.formattedSellAmount
          )}
        </div>
      </td>
      {/* timestamp  */}
      <td className="px-6 py-4 text-sm text-gray-500 text-center flex items-center justify-center gap-2">
        <span className={clsx({ "blur-md": privacyMode })}>
          {isQueryLoading ? (
            <Skeleton.Button active={true} size={"small"} shape={"round"} />
          ) : (
            displayData.dateUTC
          )}
        </span>
      </td>
      {/* see more  */}
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
        <FontAwesomeIcon icon={faEllipsisV} />
      </td>
    </tr>
  );
};
