import React, { useState, useContext } from "react";
import clsx from "clsx";
import { PrivacyModeContext } from "@/contexts";
import {
  TaxEventItemClass,
  formatAmount,
  formatCurrency,
  getCurrencySymbol,
  useIsFiatCurrency,
  useProfile,
} from "@moonscape/shared";
import { CoinIcon } from "@/components";

export const TaxEventRow = (taxEvent) => {
  const t = new TaxEventItemClass(taxEvent);
  const isFiatCurrency = useIsFiatCurrency();
  const { privacyMode } = useContext(PrivacyModeContext);

  const openModal = () => {
    console.log("Opening modal for ", t);
  };

  const isGain = t.gain > 0;
  const isLoss = t.gain < 0;

  const { profile } = useProfile();

  const currencySymbol = getCurrencySymbol(profile?.baseCurrency);

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="grid grid-cols-3 gap-4 bg-white border border-gray-200 mt-4 p-4 rounded-lg cursor-pointer hover:bg-gray-50"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={openModal}
      role="button"
      tabIndex="0"
    >
      {/* Left Section: Coin Details */}
      <div className="flex items-center space-x-4">
        <CoinIcon
          coinSymbol={t?.asset?.toLowerCase()}
          className="w-10 h-10 rounded-full"
        />
        <div>
          <div className="flex items-center space-x-2">
            <div className="text-lg font-semibold text-gray-900">{t.asset}</div>
            <div className="text-xs font-medium text-gray-500 bg-gray-100 px-2 py-1 rounded">
              {t.disposal_type}
            </div>
          </div>
          <div className="text-xs text-gray-400">{t.formattedTime()}</div>
        </div>
      </div>

      {/* Center Section: Key Data */}
      <div className="flex flex-col space-y-1">
        <div>
          <span className="text-xs font-medium text-gray-500">
            Quantity Sold
          </span>
          <span
            className={clsx("text-sm font-medium text-gray-900 ml-2", {
              "blur-md": privacyMode,
            })}
          >
            {formatAmount(t.quantity)}
          </span>
        </div>
        <div>
          <span className="text-xs font-medium text-gray-500">Cost-basis</span>
          <span
            className={clsx("text-sm font-medium text-gray-900 ml-2", {
              "blur-md": privacyMode,
            })}
          >
            £{formatAmount(t.cost, null, isFiatCurrency)}
          </span>
        </div>
      </div>

      {/* Right Section: Proceeds and Loss/Gain */}
      <div className="flex flex-col space-y-1 text-right">
        <div>
          <span className="text-xs font-medium text-gray-500">Proceeds</span>
          <span
            className={clsx("text-sm font-medium text-gray-900 ml-2", {
              "blur-md": privacyMode,
            })}
          >
            £{formatAmount(t.proceeds, null, isFiatCurrency)}
          </span>
        </div>
        <div>
          <span className="text-xs font-medium text-gray-500">
            {isGain ? "Gain" : "Loss"}
          </span>
          <span
            className={clsx(
              "text-sm font-medium ml-2",
              { "text-green-700": isGain },
              { "text-red-700": isLoss },
              {
                "blur-md": privacyMode,
              }
            )}
          >
            {formatCurrency(t.gain, currencySymbol)}
          </span>
        </div>
      </div>
    </div>
  );
};
