import React, { useEffect } from "react";

export const SelectionComponent = ({
  options,
  selectedValue,
  handleSelect,
}) => {
  return (
    <div>
      <select
        id="select"
        name="select"
        className="text-xl  w-64 block  rounded-xl border-0 p-3  text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        value={selectedValue}
        onChange={handleSelect}
      >
        <option value="">Select...</option>
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
