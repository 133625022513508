import { SelectionComponent } from "../SelectionComponent";
import { useEffect } from "react";
import {
  TaxSettingKey,
  TaxSettingProfileKey,
  TaxSettingUpdateKey,
} from "./TaxOptionsConfig";
import { useProfile } from "@moonscape/shared";
import { useState } from "react";
import React from "react";

interface TaxSelectProps {
  taxSettingKey: TaxSettingKey;
  options: { value: string; label: string }[];
  onSuccess?: () => void;
  handleSelectChange?: (value: string) => void;
}

/**
 * this is select component for tax settings used to update the profile tax settings
 * so user can set tax jurisdiction, accounting method, filing status and cgt rate etc for their jurisdiction
 */
export const TaxSelect: React.FC<TaxSelectProps> = ({
  taxSettingKey,
  /** to get the select options either from a dict or from api */
  options,
  onSuccess,
}) => {
  const { profile, setProfileTax } = useProfile();

  const profileKey = TaxSettingProfileKey[taxSettingKey];
  const [selectedValue, setSelectedValue] = useState(profile?.[profileKey]);

  useEffect(() => {
    // If the taxSettingKey is cgt_rate, pick out the rate from taxRates for the current year
    if (taxSettingKey === "cgt_rate" && profile?.taxRates) {
      const currentYear = new Date().getFullYear();
      console.log("[tax select] cgt_rate", currentYear);
      const currentYearRate = profile?.taxRates?.find(
        (rate) => rate.year === currentYear
      );

      console.log("[tax select] currentYearRate", currentYearRate);
      setSelectedValue(currentYearRate?.rate);
    } else {
      setSelectedValue(profile?.[profileKey]);
    }
  }, [profile?.[profileKey]]);

  // console.log("[tax] selectedValue: ", selectedValue);

  const handleTaxSettingSelect = (e) => {
    let value = e.target.value; // Get the selected value

    setSelectedValue(value);

    const updatedProfileTax = {
      [TaxSettingUpdateKey[taxSettingKey]]: value,
    };
    setProfileTax(updatedProfileTax);

    // console.log("Settings saved successfully", res);
    // alert(`${taxSettingKey} saved successfully`);
  };
  return (
    <div>
      <SelectionComponent
        options={options}
        selectedValue={selectedValue}
        handleSelect={handleTaxSettingSelect}
      />
      {/* list modals you might want to trigger onChangeSelect */}
    </div>
  );
};
