import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface ScreenWrapperProps {
  children: React.ReactNode;
  onClose: () => void;
  heading?: string;
}

export const ScreenWrapper = ({
  children,
  onClose,
  heading,
}: ScreenWrapperProps) => {
  return (
    <div className="relative h-full flex flex-col">
      {/* Fixed Header */}
      <div className="flex items-center justify-between px-6 py-4 border-b border-gray-200">
        <div className="w-6" /> {/* Spacer matching close button width */}
        {heading && (
          <h2 className="text-lg font-medium text-gray-900 flex-1 text-center">
            {heading}
          </h2>
        )}
        <button
          onClick={onClose}
          className="text-gray-400 hover:text-gray-500 focus:outline-none w-6"
        >
          <XMarkIcon className="h-6 w-6" />
          <span className="sr-only">Close</span>
        </button>
      </div>

      {/* Scrollable Content with Padding */}
      <div className="flex-1 overflow-y-auto px-6 py-8">{children}</div>
    </div>
  );
};
