import React, { useState, useEffect, useMemo } from "react";
import { useProfile, TaxJurisdiction } from "@moonscape/shared";
import { useOnboardingActor } from "@moonscape/shared";

import {
  TaxAccountingMethodSelect,
  TaxCGTRateSelect,
  TaxFilingStatusSelect,
  TaxJurisdictionSelect,
} from "@/components/Tax";

export const TaxSettings = () => {
  // for onboarding flow
  const actor = useOnboardingActor();
  const { send } = actor;

  const { profile, setProfileTax } = useProfile();
  const [localTaxJurisdiction, setLocalTaxJurisdiction] = useState(
    profile?.taxJurisdiction
  );

  // Sync local tax jurisdiction with profile when profile changes
  useEffect(() => {
    setLocalTaxJurisdiction(profile?.taxJurisdiction);
  }, [profile?.taxJurisdiction]);

  const handleTaxJurisdictionChange = (value) => {
    setLocalTaxJurisdiction(value);

    // don't need to trigger the mutation for optimistic update since it's handled in the TaxJurisdictionSelect component
  };

  const settingsItems = useMemo(() => {
    if (!localTaxJurisdiction) {
      return [
        {
          title: "Tax Jurisdiction",
          description: null,
          component: () => (
            <TaxJurisdictionSelect
              selectedValue={localTaxJurisdiction}
              handleTaxJurisdictionChange={handleTaxJurisdictionChange}
            />
          ),
          required: true,
          visible: true,
        },
      ];
    }

    return [
      {
        title: "Tax Jurisdiction",
        description: null,
        component: () => (
          <TaxJurisdictionSelect
            selectedValue={localTaxJurisdiction}
            handleTaxJurisdictionChange={handleTaxJurisdictionChange}
          />
        ),
        required: true,
        visible: true,
      },
      {
        title: "CGT Rate",
        // description: {
        //   text: "Your CGT Rate is 10% if you're a basic rate taxpayer. Select 20% if you're a higher or additional rate taxpayer. If your rate is different in previous years, send us a message.",
        //   link: {
        //     url: "https://www.gov.uk/capital-gains-tax/rates",
        //     text: "See HMRC for more information",
        //   },
        // },
        component: () => (
          <TaxCGTRateSelect
            handleSelect={(value) => setProfileTax({ cgt_rate: value })}
          />
        ),
        required: true,
        visible: localTaxJurisdiction === TaxJurisdiction.UK,
      },
      {
        title: "Filing Status",
        description: null,
        component: () => (
          <TaxFilingStatusSelect
            handleSelect={(value) =>
              setProfileTax({ tax_filing_status: value })
            }
          />
        ),
        required: true,
        visible: localTaxJurisdiction === TaxJurisdiction.US,
      },
      {
        title: "Accounting Method",
        description: null,
        component: () => (
          <TaxAccountingMethodSelect
            handleSelect={(value) =>
              setProfileTax({ accounting_method: value })
            }
          />
        ),
        required: true,
        visible: localTaxJurisdiction === TaxJurisdiction.US,
      },
    ];
  }, [localTaxJurisdiction]);

  return (
    <div>
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Tax Settings
          </h3>
          {/* <p className="max-w-2xl text-sm text-gray-500">
            Configure your tax preferences and link relevant information for
            accurate tax calculations.
          </p> */}
        </div>
        <div className="mt-6">
          <dl className="divide-y divide-gray-200">
            {settingsItems
              .filter((item) => item.visible)
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5"
                  >
                    <dt
                      className={
                        item.description
                          ? "col-span-2"
                          : "text-sm font-medium text-gray-500"
                      }
                    >
                      <p className="text-sm font-medium text-gray-500">
                        {item.title}
                        {item.required && (
                          <span className="text-red-500">*</span>
                        )}
                      </p>
                      {item.description?.text && (
                        <p className="max-w-40 pt-1 text-xs text-gray-400">
                          {item.description.text}
                        </p>
                      )}
                      {item.description?.link && (
                        <p className="mt-1 text-xs">
                          <a
                            href={item.description.link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="underline text-xs text-blue-500"
                          >
                            {item.description.link.text}
                          </a>
                        </p>
                      )}
                    </dt>
                    <dd
                      className={`mt-1 flex text-sm text-gray-900 sm:mt-0 ${
                        item.description ? "sm:col-span-1" : "sm:col-span-2"
                      }`}
                    >
                      <span className="flex-grow"></span>
                      <span
                        className={`${
                          item.description
                            ? "flex-shrink-0 flex ml-auto"
                            : "flex-shrink-0 flex justify-end w-full"
                        } items-start space-x-4`}
                      >
                        <item.component />
                      </span>
                    </dd>
                  </div>
                );
              })}
          </dl>
        </div>
      </div>
    </div>
  );
};
