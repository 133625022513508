import React, { useEffect, Fragment, useState, useMemo } from "react";
import CookieConsent from "react-cookie-consent";
import {
  BrowserRouter as Router,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { loadAnalytics, page, identify } from "./analytics.ts";
import { jwtDecode } from "jwt-decode";
import Lottie from "lottie-react";
import moonscapelogo from "@/moonscape.svg";

import { Routes } from "./Routes";
import { AccessContext, PrivacyModeContextProvider } from "@/contexts";
import { Screen, DesktopSidebar, MobileMenu } from "@/components";
import { axios, setAuthToken } from "@/axios";
import { ErrorBoundary } from "react-error-boundary";
import { GlobalDebug } from "./utils/remove-console";
import ReactGA from "react-ga";
import { trackEvent } from "@/analytics";
import queryString from "query-string";
import logoLoadingAnimation from "./assets/logo-loading.lottie.json";

import { OnboardingActorProvider } from "@moonscape/shared";

const EMAIL_CLAIM = "https://moonscape.app/email";
const LOGINS_COUNT_CLAIM = "https://moonscape.app/loginsCount";
const EMAIL_VERIFIED_CLAIM = "https://moonscape.app/emailVerified";

/** ErrorBoundaryFallback component  */
function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

export default function App() {
  /**
   * @REMOVE_CONSOLES
   * // remove the working of console logs
   * // remove any accidental use of console logs
   */
  useEffect(() => {
    process.env.NODE_ENV === "production" && GlobalDebug(false);
  }, []);

  const [accessToken, setAccessToken] = useState(null);
  const [loginsCount, setLoginsCount] = useState(null);
  const [emailVerified, setEmailVerified] = useState(null);

  const {
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  // @ts-ignore
  // If under test in Cypress, get credentials from "auth0Cypress" localstorage item and send event to our state management to log the user into the SPA
  // if (window.Cypress) {
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   useEffect(() => {
  //     const auth0 = JSON.parse(localStorage.getItem("auth0Cypress"));
  //     console.log("[App] auth0", auth0);
  //     // authService.send("AUTH0", {
  //     //   user: auth0.body.decodedToken.user,
  //     //   token: auth0.body.access_token,
  //     // });
  //     setAccessToken(auth0.body.access_token);
  //     setAxiosTokenInterceptor(auth0.body.access_token);
  //   }, [accessToken]);
  // } else {

  useEffect(() => {
    // load analytics - segment and GA
    loadAnalytics();
  }, []);

  // track clicks and interactions
  const history = useHistory();

  useEffect(() => {
    const handleDocumentClick = (event) => {
      const { target } = event;
      // if (target.tagName === "A" || target.tagName === "BUTTON") {
      if (target.tagName === "BUTTON") {
        // Call your tracking function here
        // console.log(`[analytics] Tracking: ${target.tagName} click`);
        // If it's a link, you might want to prevent the default navigation
        // if (target.tagName === "A") {
        //   event.preventDefault();
        //   // Programmatically navigate if using react-router
        //   navigate(target.getAttribute("href"));
        // history.push("/home");
        // }
      }
    };

    // Attach the event listener to the document
    document.addEventListener("click", handleDocumentClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [history]);

  const location = useLocation();

  // Auth0 initial access token exchange
  useEffect(() => {
    async function getAccessToken() {
      try {
        // get the access token
        const accessToken = await getAccessTokenSilently();

        // console.log("[user] accessToken ", accessToken);

        const decodedToken = jwtDecode(accessToken);

        console.log("[user] decodedToken ", decodedToken);

        // Now you can access your custom claims
        const loginsCount = decodedToken[LOGINS_COUNT_CLAIM];
        const emailVerified = decodedToken[EMAIL_VERIFIED_CLAIM];

        console.log("[user] loginsCount ", loginsCount);

        setEmailVerified(emailVerified);
        setLoginsCount(loginsCount);

        // trigger google conversion for signup
        if (loginsCount === 1) {
          // trigger google conversion for signup
          // ReactGA.event({
          //   category: "Signup",
          //   action: "Signup",
          //   label: "Signup",
          //   value: 1,
          // });

          trackEvent("signup - loginsCount 1", {
            action: "signun conversion",
            category: "signup",
            label: "signup conversion",
          });

          // track signup conversion
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "signup_account_creation", // Custom event name, used in GTM triggers
            // You can add additional event parameters here if needed
          });

          console.log("[user] signup conversion");
        }

        // check if email is verified

        if (!emailVerified) {
          history.push("/verify-email");
        }

        setAuthToken(accessToken);
        setAccessToken(accessToken);
        console.log("[analytics] getAccessTokenSilently resp");
      } catch (e) {
        console.log("[analytics] error getting access token ", e);
        // If the error is due to an expired token, redirect the user to the login page
        if (e.error === "login_required" || e.error === "invalid_token") {
          // TODO: track user login required
          trackEvent("login redirect from dashboard /", {
            action: "login redirect",
            category: "login",
            label: "login redirect",
            value: 1,
          });
          console.log("[analytics] login redirect via dashboard /");

          // Navigate to the link after a delay to ensure the event is tracked
          setTimeout(() => {
            loginWithRedirect();
          }, 250);
        }
      }
    }

    const values = queryString.parse(location.search);
    const hasError = !!values.error;

    console.log("[verify] values", values);

    if (!hasError && !isAuthenticated && !isLoading) {
      console.log("[analytics] not authenticated");
      trackEvent("login redirect from dashboard /", {
        action: "login redirect",
        category: "login",
        label: "login redirect",
        value: 1,
      });
      console.log("[analytics] login redirect via dashboard /");
      // Navigate to the link after a delay to ensure the event is tracked
      setTimeout(() => {
        loginWithRedirect();
      }, 250);
      return;
    }
    if (!hasError && isAuthenticated) {
      // identify user for segment
      identify(user?.email);
      console.log("[analytics] getting access token ", user?.email);
      getAccessToken();
    }
  }, [
    getAccessTokenSilently,
    history,
    isAuthenticated,
    isLoading,
    location.search,
    loginWithRedirect,
    user,
  ]);

  const [isFooterVisible, setIsFooterVisible] = useState(true);

  const [isMobile, setIsMobile] = useState(false);
  const [hasTrackedMobileWarning, setHasTrackedMobileWarning] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  useEffect(() => {
    const checkMobile = () => {
      if (isMobile && !hasTrackedMobileWarning) {
        trackEvent("mobile takeover - optimized for desktop", {});
        setHasTrackedMobileWarning(true);
      }
    };

    checkMobile();
  }, [hasTrackedMobileWarning, isMobile]);

  // access context stores the auth0 access token
  const value = useMemo(() => {
    // console.log("[App] useMemo accessToken", accessToken)
    return accessToken;
  }, [accessToken]);

  if (isLoading) {
    return (
      <div className="grid h-screen place-items-center">
        <div className="w-80 h-80 flex bg-black">
          <Lottie animationData={logoLoadingAnimation} loop={true} />;
        </div>
      </div>
    );
  }

  const MobileTakeover = () => {
    if (isMobile) {
      return (
        <div className="fixed inset-0 bg-white flex items-center justify-center text-center p-4 z-50">
          <div>
            <h2 className="text-2xl font-bold mb-4">
              Desktop or Mobile app Recommended <br />
              <span className="text-gray-400 font-semibold text-sm ">
                Download the mobile app for the best mobile experience
              </span>
            </h2>

            <button
              className="mt-12 bg-green-500 text-white px-4 py-2 rounded-2xl "
              onClick={() => setIsMobile(false)}
            >
              Continue anyway
            </button>
            <button
              className="mt-12 bg-blue-500 text-white px-4 py-2 rounded-2xl "
              onClick={() =>
                window.open(
                  "https://apps.apple.com/us/app/moonscape-crypto-tax-tracker/id6686407896",
                  "_blank"
                )
              }
            >
              Download mobile app
            </button>
          </div>
        </div>
      );
    }
  };
  return (
    <Router>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        <OnboardingActorProvider>
          <AccessContext.Provider value={value}>
            <PrivacyModeContextProvider>
              <MobileTakeover />
              {!isMobile && (
                <div className=" h-screen min-w-16 flex overflow-hidden bg-white">
                  {/* Static sidebar for desktop */}
                  <DesktopSidebar />

                  {/* this has all the different routes on the sidebar */}
                  <Routes />
                </div>
              )}

              {false && (
                <footer className="mobile-footer text-sm fixed inset-x-0 bottom-0 bg-gray-800 text-white text-center px-4 pt-3 md:hidden">
                  <p className="">
                    This website is optimiz ed for desktop. If you're interested
                    in a mobile version, please let us know by clicking the
                    button
                  </p>
                  <a
                    href="mailto:example@example.com?subject=Mobile%20Version%20Interest&body=I'm%20interested%20in%20a%20mobile%20version%20of%20the%20website.%20Here%20are%20my%20suggestions:"
                    className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                  >
                    <button
                      type="button"
                      className=" mx-4 mb-4 rounded-full mt-6 bg-green-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                    >
                      I want a mobile version 📱
                    </button>
                  </a>
                </footer>
              )}
              <CookieConsent
                location="bottom"
                buttonText="OK, got it!"
                cookieName="moonscape_cookie_consent"
                style={{ background: "#2B373B", paddingRight: 128 }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                // expires={150}
              >
                We use cookies to ensure you get the best experience on our
                website.
                <span style={{ fontSize: "10px" }}>Learn more</span>
              </CookieConsent>
            </PrivacyModeContextProvider>
          </AccessContext.Provider>
        </OnboardingActorProvider>
      </ErrorBoundary>
    </Router>
  );
}
