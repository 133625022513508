import React from "react";
import { createContext, useContext, useMemo, useEffect } from "react";
import { createActor } from "xstate";
import { onboardingMachine } from "../machines";

export const OnboardingActorContext = createContext<ReturnType<
  typeof createActor
> | null>(null);

export const OnboardingActorProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigation = () => {
    console.log("web navigation");
  };

  const onboardingActor = () =>
    createActor(onboardingMachine, {
      input: {
        navigation,
        taxJurisdiction: null,
        exchangeConnected: false,
        fromSettings: false,
      },
    }).start();

  return (
    <OnboardingActorContext.Provider value={onboardingActor()}>
      {children}
    </OnboardingActorContext.Provider>
  );
};

export const useOnboardingActor = () => {
  const context = useContext(OnboardingActorContext);
  if (!context) {
    throw new Error(
      "useOnboardingActor must be used within OnboardingActorProvider"
    );
  }
  return context;
};
