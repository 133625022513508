import React from "react";
import { ExchangeAPISettingsNew } from "./Exchange/ExchangeAPISettingsNew";
import { Switch, Route, useRouteMatch } from "react-router-dom";

export const APIKeySettingsPane = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={[`${path}`, `${path}/new`]}>
        <ExchangeAPISettingsNew />
      </Route>
    </Switch>
  );
};
