import React, { useRef } from "react";
import { SearchBar } from "@/components/SearchBar";
import { Exchange, usePaginatedSearch, PAGINATED_API } from "@moonscape/shared";
import { trackEvent } from "@/analytics";
import { ONBOARDING_EVENTS } from "@/analytics/events";
import { debounce } from "lodash";

interface SelectExchangeScreenProps {
  onExchangeSelect: (exchange: Exchange) => void;
}

export const SelectExchangeScreen = ({
  onExchangeSelect,
}: SelectExchangeScreenProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    data: exchanges,
    searchQuery,
    setSearchQuery,
    hasMore,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    resetSearch,
  } = usePaginatedSearch(PAGINATED_API.getExchanges);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight * 1.5) {
      if (hasMore && !isFetchingNextPage) {
        fetchNextPage();
      }
    }
  };

  // Debounced function to track search events
  const trackSearchEvent = debounce((query: string) => {
    if (query.trim()) {
      // Only track non-empty searches
      trackEvent(ONBOARDING_EVENTS.EXCHANGE_SEARCH, {
        search_query: query,
        results_count: exchanges?.length || 0,
        has_results: (exchanges?.length || 0) > 0,
      });
    }
  }, 1000); // Wait 1 second after user stops typing

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
    trackSearchEvent(query);
  };

  const handleExchangeSelect = (exchange: Exchange) => {
    // Track the exchange selection
    trackEvent(ONBOARDING_EVENTS.EXCHANGE_SELECTED, {
      exchange_id: exchange.id,
      exchange_name: exchange.exchangename,
      api_active: exchange.api_active,
      csv_active: exchange.csv_active,
      search_query: searchQuery || null, // Include the search query if it exists
    });

    onExchangeSelect(exchange);
  };

  const renderExchangeGrid = () => {
    if (isLoading && exchanges.length === 0) {
      return (
        <div className="flex items-center justify-center h-full">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-cyan-500" />
        </div>
      );
    }

    return (
      <div
        ref={containerRef}
        className="grid grid-cols-3 gap-4 overflow-y-auto h-full pr-2"
        onScroll={handleScroll}
      >
        {exchanges.map((exchange, index) => (
          <button
            key={`${exchange.id}-${index}`}
            onClick={() => handleExchangeSelect(exchange)}
            className="flex items-center p-4 border rounded-lg hover:bg-gray-50 transition-colors"
          >
            <div className="flex-shrink-0 w-12 h-12 mr-4">
              <img
                src={exchange.thumbnail_url}
                alt={exchange.name}
                className="w-full h-full object-contain"
                onError={(e) => {
                  e.currentTarget.src = "/default-exchange-icon.png";
                }}
              />
            </div>
            <div className="flex flex-col items-start text-left">
              <span className="font-medium text-gray-900">
                {exchange.exchangename}
              </span>
              {exchange.api_active && (
                <span className="text-xs text-gray-500 mt-1">
                  API Available
                </span>
              )}
            </div>
          </button>
        ))}
        {isFetchingNextPage && (
          <div className="col-span-3 flex justify-center py-4">
            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-cyan-500" />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full">
      <SearchBar
        value={searchQuery}
        onChange={(e) => handleSearchChange(e.target.value)}
        placeholder="Search exchanges..."
        className="w-full mb-4"
      />
      <div className="flex-1 min-h-0">
        {renderExchangeGrid()}
        {exchanges.length === 0 && !isLoading && (
          <div className="flex items-center justify-center h-full text-center text-gray-500">
            No exchanges found matching your search.
          </div>
        )}
      </div>
    </div>
  );
};
