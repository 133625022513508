import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  ArrowPathIcon,
  PlusCircleIcon,
  BanknotesIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

export const EmptyTaxState = () => {
  const [isDismissed, setIsDismissed] = useState(false);

  if (isDismissed) {
    return (
      <div className="px-4 py-5 sm:p-6 text-center">
        <h3 className="mt-4 text-4xl font-medium text-gray-900">💎</h3>
        <h3 className="mt-4 text-lg font-medium text-gray-600">
          Diamond hands
        </h3>
        <p className="mt-2 text-sm text-gray-500">
          Not selling? When you do, your tax disposals will show up here!
        </p>
      </div>
    );
  }

  return (
    <div className="px-4 py-5 sm:p-6 text-center relative">
      <button
        onClick={() => setIsDismissed(true)}
        className="absolute top-2 right-2 text-gray-400 hover:text-gray-500"
      >
        <XMarkIcon className="h-5 w-5" />
      </button>

      <BanknotesIcon
        className="mx-auto h-12 w-12 text-gray-400"
        aria-hidden="true"
      />

      <h3 className="mt-4 text-lg font-medium text-gray-900">
        No tax events found
      </h3>

      <p className="mt-2 text-sm text-gray-500">
        Get started by connecting your exchanges or importing your transaction
        history
      </p>

      <div className="mt-6 flex flex-col sm:flex-row gap-3 justify-center">
        <Link
          to="/settings/exchange/new"
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-cyan-600 hover:bg-cyan-700"
        >
          <PlusCircleIcon className="mr-2 h-5 w-5" />
          Connect Exchange
        </Link>

        <Link
          to="/settings/import"
          className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          <ArrowPathIcon className="mr-2 h-5 w-5" />
          Import CSV
        </Link>
      </div>
    </div>
  );
};
