import React from "react";
import { Slide } from "./Slide";
import { useOnboardingActor } from "@moonscape/shared";
import { useSelector } from "@xstate/react";

export const SyncingScreen = () => {
  const actor = useOnboardingActor();
  const { send } = actor;
  const { isFromSettings, exchangeConnected, lastConnectedExchange, isCSV } =
    useSelector(actor, (state) => ({
      isFromSettings: state.context.fromSettings,
      exchangeConnected: state.context.exchangeConnected,
      lastConnectedExchange: state.context.lastConnectedExchange,
      isCSV: state.context.isCSV,
    }));

  const description = (
    <div className="space-y-4">
      {exchangeConnected && lastConnectedExchange && !isCSV && (
        <p className="text-lg text-center text-green-500 mb-4">
          ✓ {lastConnectedExchange} connected successfully
        </p>
      )}
      {exchangeConnected && lastConnectedExchange && isCSV && (
        <p className="text-lg text-center text-green-500 mb-4">
          ✓ {lastConnectedExchange} transactions uploaded successfully
        </p>
      )}
      <p className="text-xl text-center text-gray-700">
        {exchangeConnected && !isCSV
          ? "Your exchange is now connected and we're syncing your transactions."
          : "You can always import more transactions later from the Exchanges tab."}
      </p>
      {/* <p className="text-xl text-center text-gray-700">
        {exchangeConnected
          ? "Syncing can take some time. "
          : "When you do connect an exchange, syncing can take a while. "}
      </p> */}
      {exchangeConnected && !isCSV && (
        <p className="text-xl text-center text-gray-700">
          We'll send you an email when it's ready.
        </p>
      )}
      <p className="text-xl text-center text-gray-700">
        Connect more exchanges and wallets in Settings.
      </p>
    </div>
  );

  return (
    <Slide
      heading="How syncing and tax work"
      description={description}
      onBack={() => send({ type: "BACK" })}
      primaryButton={{
        text: isFromSettings ? "Close" : "Next",
        onPress: () => send({ type: "NEXT" }),
      }}
    />
  );
};
