import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface ScreenWrapperProps {
  children: React.ReactNode;
  onClose: () => void;
}

export const ScreenWrapper = ({ children, onClose }: ScreenWrapperProps) => {
  return (
    <div className="relative h-full flex flex-col">
      {/* Fixed Header */}
      <div className="flex items-center justify-end px-6 py-4 border-b border-gray-200">
        <button
          onClick={onClose}
          className="text-gray-400 hover:text-gray-500 focus:outline-none"
        >
          <XMarkIcon className="h-6 w-6" />
          <span className="sr-only">Close</span>
        </button>
      </div>

      {/* Scrollable Content */}
      <div className="flex-1 overflow-y-auto">{children}</div>
    </div>
  );
};
