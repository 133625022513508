export enum TaxSettingKey {
  TaxJurisdiction = "tax_jurisdiction",
  AccountingMethod = "accounting_method",
  TaxFilingStatus = "tax_filing_status",
  CGTRate = "cgt_rate",
}

export const TaxSettingProfileKey = {
  [TaxSettingKey.TaxJurisdiction]: "taxJurisdiction",
  [TaxSettingKey.AccountingMethod]: "accountingMethod",
  [TaxSettingKey.TaxFilingStatus]: "taxFilingStatus",
  [TaxSettingKey.CGTRate]: "cgtRate",
};

export const TaxSettingUpdateKey = {
  [TaxSettingKey.TaxJurisdiction]: "tax_jurisdiction",
  [TaxSettingKey.AccountingMethod]: "accounting_method",
  [TaxSettingKey.TaxFilingStatus]: "tax_filing_status",
  [TaxSettingKey.CGTRate]: "cgt_rate",
};

const taxJurisdictionOptions = [
  { value: "United Kingdom", label: "United Kingdom 🇬🇧" },
  { value: "United States", label: "United States 🇺🇸" },
];

export const taxSettingsOptions: Record<
  TaxSettingKey,
  { value: string; label: string }[]
> = {
  [TaxSettingKey.TaxJurisdiction]: [
    { value: "United Kingdom", label: "United Kingdom 🇬🇧" },
    { value: "United States", label: "United States 🇺🇸" },
  ],
  [TaxSettingKey.AccountingMethod]: [
    { value: "LIFO", label: "LIFO" },
    { value: "FIFO", label: "FIFO" },
    { value: "HIFO", label: "HIFO" },
  ],
  [TaxSettingKey.CGTRate]: [
    { value: "10", label: "10% (Basic rate)" },
    { value: "20", label: "20% (Higher rate)" },
  ],
  [TaxSettingKey.TaxFilingStatus]: [],
};
