import React, { useState } from "react";
import { Exchange } from "@moonscape/shared";
import {
  KeyIcon,
  DocumentTextIcon,
  BellIcon,
  CheckIcon,
  ArrowLeftIcon,
} from "@heroicons/react/24/outline";
import { trackEvent } from "@/analytics";

interface ConnectionOptionsScreenProps {
  exchange: Exchange;
  onBack: () => void;
  onSelectOption: (option: "api" | "csv" | "interest") => void;
}

export const ConnectionOptionsScreen = ({
  exchange,
  onBack,
  onSelectOption,
}: ConnectionOptionsScreenProps) => {
  const [notified, setNotified] = useState(false);

  const handleNotifyMeClick = () => {
    // Track analytics event
    console.log("Notify Me clicked for exchange:", exchange.exchangename);

    // Set notified state to true
    setNotified(true);
    trackEvent("Notify Me", { exchange: exchange.exchangename });
  };

  return (
    <div className="space-y-4">
      <button
        onClick={onBack}
        className="flex items-center space-x-3 mb-6 text-gray-500 hover:text-gray-700 group"
      >
        <ArrowLeftIcon className="h-5 w-5" />
        <div className="flex items-center">
          <img
            src={exchange?.thumbnail_url}
            alt={exchange?.name}
            className="w-8 h-8 object-contain mr-2"
          />
          <h3 className="text-lg font-medium">
            Connect {exchange?.exchangename}
          </h3>
        </div>
      </button>

      <div className="grid grid-cols-2 gap-4">
        {/* API Connection Option */}
        <button
          onClick={() => onSelectOption("api")}
          disabled={!exchange.api_active}
          className={`
            relative flex flex-col items-center justify-center p-6 rounded-lg border
            ${
              exchange.api_active
                ? "border-cyan-200 bg-cyan-50 hover:bg-cyan-100 cursor-pointer"
                : "border-gray-200 bg-gray-50 cursor-not-allowed opacity-60"
            }
          `}
        >
          <KeyIcon className="h-8 w-8 mb-3 text-cyan-600" />
          <span className="text-sm font-medium">Connect via API</span>
          {!exchange.api_active && (
            <>
              <span className="text-xs text-gray-500 mt-1">Coming soon</span>
              <p className="text-xs text-gray-500 mt-1">
                You can import via CSV in the meantime
              </p>
              <div className="flex items-center space-x-1 mt-4">
                <>
                  <p className="text-xs text-gray-500 mt-1">
                    We will notify you when API integrations are supported for{" "}
                    {exchange.exchangename}
                  </p>
                </>
              </div>
            </>
          )}
        </button>

        {/* CSV Connection Option */}
        <button
          onClick={() => onSelectOption("csv")}
          // disabled={!exchange.csv_active}
          className={`
            flex flex-col items-center justify-center p-6 rounded-lg border
            ${
              // exchange.csv_active
              true
                ? "border-cyan-200 bg-cyan-50 hover:bg-cyan-100 cursor-pointer"
                : "border-gray-200 bg-gray-50 cursor-not-allowed opacity-60"
            }
          `}
        >
          <DocumentTextIcon className="h-8 w-8 mb-3 text-cyan-600" />
          <span className="text-sm font-medium">Upload CSV</span>
          {/* {!exchange.csv_active && ( */}
          {false && (
            <span className="text-xs text-gray-500 mt-1">Coming soon</span>
          )}
        </button>
      </div>
    </div>
  );
};
