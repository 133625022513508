import React from "react";
import {
  CloudArrowDownIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { useSyncProgress } from "@/hooks/useSyncProgress";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export const SyncProgressBar = () => {
  const { data: syncJobs } = useSyncProgress();

  if (!syncJobs?.length) return null;

  const FIVE_HOURS = 5 * 60 * 60 * 1000; // 5 hours in milliseconds
  const now = new Date().getTime();

  const processingJobs = syncJobs.filter((job) => job.status === "processing");
  const completedJobs = syncJobs.filter((job) => {
    if (job.status !== "completed" || !job.updated_at) return false;
    const completedTime = new Date(job.updated_at).getTime();
    return now - completedTime < FIVE_HOURS;
  });
  const failedJobs = syncJobs.filter((job) => job.status === "failed");

  const formatTime = (dateString: string) => {
    try {
      return dayjs(dateString).fromNow();
    } catch (e) {
      console.error("Invalid date:", dateString);
      return "Just now";
    }
  };

  return (
    <div className="flex-1 max-w-md mx-auto">
      <div className="py-1">
        {processingJobs.map((job) => (
          <div
            key={`${job.exchange}-${job.created_at}`}
            className="flex items-center space-x-3"
          >
            <CloudArrowDownIcon className="h-5 w-5 text-cyan-500 animate-bounce" />
            <div className="flex-1">
              <div className="flex items-center justify-between">
                <span className="text-sm font-medium text-gray-900">
                  Syncing {job.exchange}...
                </span>
                <span className="text-sm text-gray-500">
                  Started {formatTime(job.created_at)}
                </span>
              </div>
              <div className="mt-1 relative">
                <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-200">
                  <div
                    className="animate-pulse bg-cyan-500 rounded h-2 w-full"
                    style={{ animation: "progress 2s ease-in-out infinite" }}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}

        {completedJobs.length > 0 && (
          <div className="mt-1 flex items-center text-sm text-gray-500">
            <CheckCircleIcon className="h-4 w-4 text-green-500 mr-1" />
            <span>
              {completedJobs.map((job) => job.exchange).join(", ")} synced{" "}
              {formatTime(completedJobs[0].updated_at)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
