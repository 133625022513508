import { useQuery } from "@tanstack/react-query";
import { authorizedAxios } from "@/axios";
import { useAuth0 } from "@auth0/auth0-react";

interface SyncJob {
  exchange: string;
  startTime: string;
  endTime?: string;
  status: "processing" | "completed" | "failed";
}

export const useSyncProgress = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: ["syncProgress"],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const { data } = await authorizedAxios(token).get<SyncJob[]>(
        "/sync/status"
      );
      return data;
    },
    refetchInterval: 5000, // Refetch every 5 seconds
    refetchIntervalInBackground: true,
  });
};
