import { useGetTaxFilingStatuses } from "@/hooks";

import { TaxSettingKey } from "./TaxOptionsConfig";
import { TaxSelect } from "./TaxSelect";
import React from "react";

export const TaxFilingStatusSelect = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const { filingStatuses } = useGetTaxFilingStatuses();

  console.log("[new] filingStatus", filingStatuses);
  const taxFilingStatusOptions = filingStatuses?.map((status) => ({
    value: status,
    label: status,
  }));

  return (
    <TaxSelect
      taxSettingKey={TaxSettingKey.TaxFilingStatus}
      options={taxFilingStatusOptions}
      onSuccess={onSuccess}
    />
  );
};
