import React, { useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { useOnboardingActor } from "@moonscape/shared";
import { useSelector } from "@xstate/react";
import { WelcomeScreen } from "./WelcomeScreen";
import { TaxCountryScreen } from "./TaxCountryScreen";
import { ExchangeScreen } from "./ExchangeScreen";
import { SyncingScreen } from "./SyncingScreen";
import { HelpScreen } from "./HelpScreen";
import { OnboardingTaxSettings } from "./TaxSettings";
import { ScreenWrapper } from "./ScreenWrapper";
import { trackEvent } from "@/analytics";
import { ONBOARDING_EVENTS, EVENT_CATEGORIES } from "@/analytics/events";

export const OnboardingModal = () => {
  const actor = useOnboardingActor();
  const currentState = useSelector(actor, (state) => state.value);
  const showTaxSettings = useSelector(
    actor,
    (state) => state.matches("taxCountry") && state.context.showTaxSettings
  );

  // Track step transitions
  useEffect(() => {
    if (currentState === "completed" || currentState === "postponed") {
      return;
    }

    trackEvent(ONBOARDING_EVENTS.STEP_TRANSITION, {
      category: EVENT_CATEGORIES.ONBOARDING,
      from_step: actor.getSnapshot().history?.value,
      to_step: currentState,
      tax_settings_shown: showTaxSettings,
    });
  }, [currentState, showTaxSettings]);

  const handleClose = () => {
    trackEvent(ONBOARDING_EVENTS.STEP_SKIP, {
      category: EVENT_CATEGORIES.ONBOARDING,
      skipped_from_step: currentState,
    });
    actor.send({ type: "POSTPONE" });
  };

  // Don't render the modal if we're in completed or postponed state
  if (currentState === "completed" || currentState === "postponed") {
    return null;
  }

  const renderScreen = () => {
    const screen = (() => {
      switch (currentState) {
        case "welcome":
          return <WelcomeScreen />;
        case "help":
          return <HelpScreen />;
        case "taxCountry":
          return <OnboardingTaxSettings />;

        case "exchange":
          return <ExchangeScreen />;
        case "syncing":
          return <SyncingScreen />;
        default:
          return null;
      }
    })();

    return <ScreenWrapper onClose={handleClose}>{screen}</ScreenWrapper>;
  };

  return (
    <Dialog
      open={true}
      onClose={() => {}}
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

        <div className="relative bg-white rounded-lg w-full max-w-3xl mx-4">
          <div className="h-[600px] flex flex-col">{renderScreen()}</div>
        </div>
      </div>
    </Dialog>
  );
};
