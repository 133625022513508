import { useQuery } from "@tanstack/react-query";
import { axios, authorizedAxios } from "@/axios";
import { useAuth } from "@moonscape/shared";

export const useTradesData = (
  page,
  exchange,
  asset,
  isSearching,
  searchedValue,
  onLoading
) => {
  const { user, isAuthenticated, isLoading, getToken } = useAuth();
  // userId
  const email = user?.email;

  console.log("useTradesData-page", page);
  // console.log(`Searched DATA FROM useTradesData ${searchedValue}`);
  let exchangeQueryParam = "";
  let assetQueryParam = "";

  if (asset) assetQueryParam = `&asset=${asset}`;
  if (exchange) exchangeQueryParam = `&exchange=${exchange}`;

  const getTradesData = async () => {
    try {
      const token = await getToken();
      let url = isSearching
        ? `/trades/search?search=${searchedValue}&cursor=${page}`
        : `/trades?cursor=${page}${exchangeQueryParam}${assetQueryParam}`;

      const res = await authorizedAxios(token).get(
        // TODO: add filter here for exchange and asset

        url
      );
      // console.log("useTradesData() url ", url);

      // console.log("/trades res", res.data, user?.email);
      return res.data;
    } catch (error) {
      console.log("[useTradesData] error ", error);
      return { error };
    }
  };

  // useQuery key to cache
  const useQueryKey = isSearching
    ? `transactions-search-${searchedValue}-${page ?? 0}`
    : `transactions-${exchange}-${asset}-${page ?? 0}`;

  const {
    isLoading: isQueryLoading,
    isSuccess,
    isIdle,
    error,
    isError,
    data: trades,
    isPreviousData,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: [useQueryKey, page],
    queryFn: getTradesData,

    // The query will not execute until the userId exists
    enabled: !!email,

    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  isSearching && isFetching ? onLoading(isFetching) : onLoading(false);

  return { isQueryLoading, isIdle, error, trades, isPreviousData, refetch };
};

export const useSingleTransactionData = (extTxnId, onLoading) => {
  const { user, isAuthenticated, isLoading, getToken } = useAuth();
  // userId
  const email = user?.email;

  const getSingleTransactionData = async () => {
    try {
      const token = await getToken();
      let url = `/transaction/${extTxnId}`;

      const res = await authorizedAxios(token).get(url);
      console.log("useSingleTransactionData() url ", url);

      console.log("/transaction res", res.data, user?.email);
      return res.data;
    } catch (error) {
      console.log("[useSingleTransactionData] error ", error);
      return { error };
    }
  };

  // useQuery key to cache
  const useQueryKey = `transaction/${extTxnId}`;

  const {
    isLoading: isQueryLoading,
    isSuccess,
    isIdle,
    error,
    isError,
    data: transaction,
    isPreviousData,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: [useQueryKey],
    queryFn: getSingleTransactionData,

    // The query will not execute until the userId exists
    enabled: !!email,

    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  isFetching ? onLoading(isFetching) : onLoading(false);

  return {
    isQueryLoading,
    isIdle,
    error,
    transaction,
    isPreviousData,
    refetch,
  };
};
