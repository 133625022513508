import { QueryFunction, useQuery, UseQueryResult } from "@tanstack/react-query";
import { authorizedAxios } from "@/axios";
// import { useAuth0 } from "@auth0/auth0-react";
import { List } from "postcss/lib/list";

import { useAuth } from "@moonscape/shared";

export type Position = {
  totals: List;
  exchange: string;
  asset: string; // TODO: have type list of assets
  amount: number;
};

export type PositionsResponse = Position[];

export const usePositionsData = (): Partial<
  UseQueryResult<Position[], Error | undefined>
> => {
  // const { getAccessTokenSilently } = useAuth0();

  const { getToken } = useAuth();

  /** fetch function that react-query will use to fetch position data */
  const getPositionsData: QueryFunction<Position[]> = async () => {
    try {
      const token = await getToken();

      const res = await authorizedAxios(token).get(`/positions`);

      // const res = { data: "hello" };
      // console.log("[positions] api res", res);

      return res.data;
    } catch (error) {
      // For React Query to determine a query has errored, the query function must throw. Any error that is thrown in the query function will be persisted on the error state of the query.
      // https://react-query-v3.tanstack.com/guides/query-functions#handling-and-throwing-errors
      throw error;
    }
  };

  const {
    isLoading: isQueryLoading,
    isSuccess,
    isIdle,
    isError,
    error,
    data,
  } = useQuery({
    queryKey: ["positions"],
    queryFn: getPositionsData,

    // The query will not execute until the userId exists
    refetchOnWindowFocus: true,

    onError: (error) => {
      console.log("[me] useQuery onError", error);

      // toast.error(`Something went wrong: ${error?.message}`)
    },
  });
  // Check for the specific error message
  const customError =
    data?.error === "tax jurisdiction not set"
      ? "Complete your Tax Settings to see Balances"
      : undefined;

  return {
    isLoading: isQueryLoading,
    isIdle,
    isError,
    data,
    error,
    customError, // Return the custom error message
  } as Partial<UseQueryResult<Position[], Error | undefined>>;
};
