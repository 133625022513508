import React from "react";
import { Slide } from "./Slide";
import { useOnboardingActor } from "@moonscape/shared";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface WelcomeScreenProps {
  onClose?: () => void;
}

export const WelcomeScreen = ({ onClose }: WelcomeScreenProps) => {
  const { send } = useOnboardingActor();

  const description = (
    <div className="space-y-3">
      <p className="text-xl text-center text-gray-700 mb-4">
        Here's what we'll do next:
      </p>
      <div className="space-y-3 text-left">
        <div className="flex items-center">
          <span className="text-blue-500 mr-2">1.</span>
          <span className="text-lg text-gray-700">Select your tax country</span>
        </div>
        <div className="flex items-center">
          <span className="text-blue-500 mr-2">2.</span>
          <span className="text-lg text-gray-700">
            Connect your first exchange
          </span>
        </div>
        <div className="flex items-center">
          <span className="text-blue-500 mr-2">3.</span>
          <span className="text-lg text-gray-700">
            Wait for your portfolio to sync
          </span>
        </div>
        <div className="flex items-center">
          <span className="text-blue-500 mr-2">4.</span>
          <span className="text-lg text-gray-700">
            View your portfolio and tax calculations
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="relative">
      {onClose && (
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 focus:outline-none"
        >
          <XMarkIcon className="h-6 w-6" />
          <span className="sr-only">Close</span>
        </button>
      )}
      <Slide
        heading="Let's get you started"
        description={description}
        isFirstSlide={true}
        primaryButton={{
          text: "Start",
          onPress: () => send({ type: "NEXT" }),
        }}
        secondaryButton={{
          text: "Skip for now",

          onPress: () => send({ type: "POSTPONE" }),
        }}
      />
    </div>
  );
};
