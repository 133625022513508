import { TaxEventRow } from "./TaxEventRow";
import { useTaxEventsPagination } from "@moonscape/shared";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import calendar from "dayjs/plugin/calendar";

dayjs.extend(relativeTime);
dayjs.extend(calendar);

export const TaxEventsTable = ({ taxYear }) => {
  const {
    taxEvents,
    isQueryLoading,
    error,
    previousPagination,
    nextPagination,
    resetPagination,
    hasMore,
    remaining,
    page,
    isPreviousData,
  } = useTaxEventsPagination({
    taxYear: taxYear,
    onLoading: () => {
      // console.log("onLoadingHandler");
    },
  });

  const items = taxEvents || [];

  console.log("[te] items", items);

  // Group tax events by date
  const groupedEvents = items.reduce((acc, event) => {
    const eventDate = dayjs(event.datetime);
    const formattedDate = eventDate.calendar(null, {
      sameDay: "[Today], DD MMM YYYY", // format for the same day
      nextDay: "[Tomorrow], DD MMM YYYY", // format for the next day
      nextWeek: "dddd, DD MMM YYYY", // format for the next week
      lastDay: "[Yesterday], DD MMM YYYY", // format for the previous day
      lastWeek: "[Last] dddd, DD MMM YYYY", // format for the previous week
      sameElse: "DD MMM YYYY", // format for any other day
    });

    if (!acc[formattedDate]) {
      acc[formattedDate] = [];
    }
    acc[formattedDate].push(event);
    return acc;
  }, {});

  if (Object.keys(groupedEvents).length === 0) {
    return (
      <div className="text-center py-10 text-gray-500">
        <p>Looks like there are no tax events for the selected tax year.</p>
      </div>
    );
  }

  const count = items?.length;
  const total = remaining;

  return (
    <div>
      {Object.keys(groupedEvents).map((date) => {
        return (
          <div key={date}>
            <h3 className="text-sm font-medium my-2 text-gray-500">{date}</h3>
            {groupedEvents[date].map((taxEvent) => (
              <TaxEventRow key={taxEvent.txn_id} {...taxEvent} />
            ))}
          </div>
        );
      })}
      <nav
        aria-label="Pagination"
        className="flex items-center justify-between bg-white px-4 py-3 sm:px-6"
      >
        <div className="hidden sm:block">
          <p className="text-sm text-gray-700">
            Showing{" "}
            <span className="font-medium">{isNaN(count) ? 0 : count}</span> to{" "}
            <span className="font-medium">{isNaN(total) ? 0 : total}</span>{" "}
            remaining
          </p>
        </div>
        <div className="flex flex-1 justify-between sm:justify-end">
          <button
            onClick={previousPagination}
            disabled={page === 1 || isPreviousData}
            className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            Previous
          </button>
          <button
            onClick={nextPagination}
            disabled={!hasMore || isPreviousData}
            className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            Next
          </button>
        </div>
      </nav>
    </div>
  );
};
