import React, { useState } from "react";
import axiosLib from "axios";
import { TradeHeadings, TradeItem, TradeListError } from "@/components";
import { ReadyToDiveIn } from "@/screens/TradesScreen/ReadyToDiveIn";
import { useProfile } from "@moonscape/shared";

export const axios = axiosLib.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

const generateEmptyItems = () =>
  Array(5).fill({
    id: "",
    tradeid: "",
    type: "",
    exchange: "",
    timestamp: "",
    datetime: "",
    buyasset: "",
    buyqty: "",
    sellasset: "",
    sellqty: "",
    feeqty: "",
    feeasset: "",
    wallet: "",
    side: "",
  });

/** props comes from RecentActivity where it does a React.cloneElement and passes trades down from TradesScreen */
export const TradeList = ({
  exchange,
  asset,
  isSearching,
  trades,
  isQueryLoading,
  hasLinkedExchangeAccount,
  error,
}) => {
  const [isError, setIsError] = useState(false);
  const {
    isQueryLoading: isProfileQueryLoading,
    error: profileQueryError,
    profile,
  } = useProfile();
  // let items = trades?.items;

  const tradeListErrorProps = () => {
    const items = isQueryLoading ? undefined : trades?.items;
    return { error, isSearching, setIsError, trades, items };
  };

  const TradeTable = ({ children }) => (
    <>
      <div className="mt-4 flex flex-col">
        <div className="sm:block md:hidden">
          <div className="grid grid-cols-1 gap-4">
            <TradeListError {...tradeListErrorProps} />
            {children}
          </div>
        </div>
        <div className="hidden md:block lg:block">
          <div className="align-middle inline-block min-w-full">
            <div className="overflow-x-auto rounded-lg shadow border-b border-gray-200 sm:rounded-lg">
              <TradeListError {...tradeListErrorProps} />
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  // this handles the case where the user has no linked exchange accounts
  // if (!profile?.hasLinkExchangeAccounts) {
  //   return (
  //     <div className="">
  //       <TradeTable>
  //         <div className="bg-gray-50">
  //           <TradeHeadings />
  //           <ReadyToDiveIn />
  //         </div>
  //       </TradeTable>
  //     </div>
  //   );
  // }

  return (
    <TradeTable>
      <table className="border border-collapse w-full">
        <TradeHeadings />
        <tbody className="bg-white divide-y divide-gray-200">
          {!isQueryLoading ? (
            trades?.items?.length > 0 ? (
              trades?.items.map((txn: any) => (
                <TradeItem
                  screen="md"
                  isQueryLoading={isQueryLoading}
                  {...txn}
                />
              ))
            ) : (
              <TradeItem screen="md" isEmpty={true} text="No results" />
            )
          ) : (
            Array(10)
              .fill(0)
              .map((txn) => (
                <TradeItem screen="md" isQueryLoading={isQueryLoading} />
              ))
          )}
        </tbody>
      </table>
    </TradeTable>
  );
};
