import { TaxSettingKey, taxSettingsOptions } from "./TaxOptionsConfig";

import { TaxSelect } from "./TaxSelect";
import React from "react";

export const TaxCGTRateSelect = ({ onSuccess }: { onSuccess?: () => void }) => {
  const key = TaxSettingKey.CGTRate;
  return (
    <TaxSelect
      taxSettingKey={key}
      options={taxSettingsOptions[key]}
      onSuccess={onSuccess}
    />
  );
};
