import {
  TradeItemProps,
  TxnType,
  FormattedTxnType,
  formatPrice,
  formatAmount,
  dayjs,
  AuditTxn,
} from "@moonscape/shared";

export class AuditTradeItemClass {
  // class property types

  tradeid: string;
  type: string;
  exchange: string;
  timestamp: string;
  datetime: string;
  buyasset: string;
  buyqty: string;
  sellasset: string;
  sellqty: string;
  feeqty: string;
  feeasset: string;
  wallet: string;
  side: string;
  userId: string;
  // added properties for audit
  buyvalue: number;
  sellvalue: number;
  feevalue: number;
  price: string;
  importtype: string;
  ccxt_id: string;
  sellvalueasset: string;
  buyvalueasset: string;
  created_at: string;
  updated_at: string;
  raw_exchange_txn_id: string;
  ext_txn_id: string;
  txn_id: string;
  value: number;
  valuerate: number;
  valueasset: string;
  valuenote: string;
  feevaluerate: number;
  feevaluenote: string;
  final_value: number;
  final_valuerate: number;
  final_feevalue: number;
  final_feevaluerate: number;
  final_valueasset: string;

  category: string;

  // coingeckoIds
  buyCoinGeckoId: string;
  sellCoinGeckoId: string;

  // columns denoting missing fields
  missing_final_feevalue: boolean;
  missing_final_value: boolean;

  // audit fields
  errorMsg?: string;

  constructor(tradeItem) {
    // Type guard to check if txn is an AuditTxn
    const isAuditTxn = (txn: any): txn is AuditTxn => {
      return (txn as AuditTxn)?.error_data !== undefined;
    };

    let txnItem = tradeItem;

    if (isAuditTxn(tradeItem)) {
      txnItem = tradeItem.error_data;
      this.errorMsg = tradeItem?.error_msg;
    }

    this.tradeid = txnItem?.tradeid;
    this.type = txnItem?.type;
    this.exchange = txnItem?.exchange;
    this.timestamp = txnItem?.timestamp;
    this.datetime = txnItem?.datetime;
    this.buyasset = txnItem?.buyasset;
    this.buyqty = txnItem?.buyqty;
    this.sellasset = txnItem?.sellasset;
    this.sellqty = txnItem?.sellqty;
    this.feeqty = txnItem?.feeqty;
    this.feeasset = txnItem?.feeasset;
    this.wallet = txnItem?.wallet;
    this.side = txnItem?.side;
    this.userId = txnItem?.userId;

    // added properties
    this.buyvalue = txnItem?.buyvalue;
    this.sellvalue = txnItem?.sellvalue;
    this.feevalue = txnItem?.feevalue;
    this.price = txnItem?.price;
    this.importtype = txnItem?.importtype;
    this.ccxt_id = txnItem?.ccxt_id;
    this.sellvalueasset = txnItem?.sellvalueasset;
    this.buyvalueasset = txnItem?.buyvalueasset;
    this.created_at = txnItem?.created_at;
    this.updated_at = txnItem?.updated_at;
    this.raw_exchange_txn_id = txnItem?.raw_exchange_txn_id;
    this.ext_txn_id = txnItem?.ext_txn_id;
    this.txn_id = txnItem?.txn_id;
    this.value = txnItem?.value;
    this.valuerate = txnItem?.valuerate;
    this.valueasset = txnItem?.valueasset;
    this.valuenote = txnItem?.valuenote;
    this.feevaluerate = txnItem?.feevaluerate;
    this.feevaluenote = txnItem?.feevaluenote;
    this.final_value = txnItem?.final_value;
    this.final_valuerate = txnItem?.final_valuerate;
    this.final_feevalue = txnItem?.final_feevalue;
    this.final_feevaluerate = txnItem?.final_feevaluerate;
    this.final_valueasset = txnItem?.final_valueasset;
    // columns denoting missing fields
    this.missing_final_feevalue = txnItem?.missing_final_feevalue;
    this.missing_final_value = txnItem?.missing_final_value;

    // coingeckoIds
    this.buyCoinGeckoId = txnItem?.buyCoinGeckoId;
    this.sellCoinGeckoId = txnItem?.sellCoinGeckoId;

    // Categorize the transaction during initialization - at the end of the constructor
    this.category = "unknownType"; // default value
    this.categorizeTxn();
  }
  // Categorize the transaction type
  categorizeTxn() {
    if (this.isTradeType()) {
      this.category = "tradeType";
    } else if (this.isTransferType()) {
      this.category = "transferType";
    } else if (this.isTransferType()) {
      this.category = "transferType";
    } else if (this.isBlockchainTransferType()) {
      this.category = "blockchainTransferType";
    } else if (this.isInterestType()) {
      this.category = "interestType";
    } else {
      this.category = "unknownType"; // Fallback for uncategorized transactions
    }
  }

  getErrorMsg() {
    console.log("error_msg", this.errorMsg);
    return this.errorMsg ?? "";
  }

  getCcy1() {
    return this.getAssetPair().split("/")?.[0] ?? this.getAsset();
  }
  getCoingeckoIdCcy1() {
    return this.getCcy1() === this.buyasset
      ? this.buyCoinGeckoId ?? this.buyasset
      : this.sellCoinGeckoId ?? this.sellasset;
  }
  getCoingeckoIdCcy2() {
    return this.getCcy2() === this.sellasset
      ? this.sellCoinGeckoId ?? this.sellasset
      : this.buyCoinGeckoId ?? this.buyasset;
  }

  getCcy2() {
    return this.getAssetPair().split("/")?.[1] ?? this.getAsset();
  }

  getExchangeName() {
    switch (this.exchange) {
      case "coinbase":
        return "Coinbase";
      case "binance":
        return "Binance";
      case "kraken":
        return "Kraken";
      case "cryptocom":
        return "Crypto.com";
    }
  }

  // getFormattedSide() {
  //   if (this.side === "buy") return "Buy";
  //   if (this.side === "sell") return "Sell";
  // }

  getSideColor() {
    if (this.isTransferType()) return "grey";
    return `${this.side === "buy" ? "green" : "red"}`;
  }

  getTransferColor() {
    if (this.type === TxnType.DEPOSIT) return "green";
    if (this.type === TxnType.WITHDRAWAL) return "blue";
    if (this.type === TxnType.INTEREST) return "indigo";
  }

  formattedTimestamp() {
    if (this.datetime !== "") {
      return dayjs(this.datetime).utc().format("MMM D YYYY, HH:mm:ss");
    } else {
      return "";
    }
  }

  formattedTime() {
    if (this.datetime !== "") {
      // return dayjs(this.datetime).utc().format("h:mm A");
      return dayjs(this.datetime).utc().format("HH:mm:ss");
    } else {
      return "";
    }
  }

  /**
   * get amount - should handle transfers and interest
   * @returns formattedAmount
   */
  getFormattedAmount() {
    if (this.isTradeType()) {
      if (this.side === "sell") {
        return this.sellqty !== null ? `-${formatAmount(this.sellqty)}` : "-";
      } else {
        return this.buyqty !== null ? `+${formatAmount(this.buyqty)}` : "-";
      }
    } else if (this.isTransferType()) {
      if (this.type === TxnType.DEPOSIT) {
        return this.buyqty !== null ? `+${formatAmount(this.buyqty)}` : "-";
      } else if (this.type === TxnType.WITHDRAWAL) {
        // return this.sellqty !== null ? `-${formatAmount(this.sellqty)}` : "-";
      }
    } else if (this.isInterestType()) {
      return this.buyqty !== null ? `+${formatAmount(this.buyqty)}` : "-";
    }
  }

  getFormattedAmountWithCurrency() {
    return `${this.getFormattedAmount()} ${this.getAsset()}`;
  }

  getCounterAmount() {
    if (this.isTradeType()) {
      if (this.side === "sell") {
        return this.buyqty !== null ? `+${formatAmount(this.buyqty)}` : "-";
      } else {
        return this.sellqty !== null ? `-${formatAmount(this.sellqty)}` : "-";
      }
    } else if (this.isTransferType()) {
      if (this.type === TxnType.DEPOSIT) {
        // return this.buyqty !== null ? `+${formatAmount(this.buyqty)}` : "-";
      } else if (this.type === TxnType.WITHDRAWAL) {
        return this.sellqty !== null ? `-${formatAmount(this.sellqty)}` : "-";
      }
    }
  }

  getAsset() {
    // console.log("buyasset", {
    //   buyasset: this.buyasset,
    //   sellasset: this.sellasset,
    // });

    if (this.isInterestType()) {
      return this.buyasset;
    }

    if (this.isTradeType()) {
      return this.side === "buy" ? this.buyasset : this.sellasset;
    }

    if (this.isTransferType() && this.type === TxnType.DEPOSIT) {
      return this.buyasset;
    }
    if (this.isTransferType() && this.type === TxnType.WITHDRAWAL) {
      return this.sellasset;
    }
  }

  getCounterAsset() {
    return this.side === "buy" ? this.sellasset : this.buyasset;
  }
  // Methods for categorization
  isTransferType() {
    return [TxnType.DEPOSIT, TxnType.WITHDRAWAL].includes(this.type as TxnType);
  }

  isInterestType() {
    return [TxnType.INTEREST, TxnType.STAKING_REWARD].includes(
      this.type as TxnType
    );
  }

  isBlockchainTransferType() {
    return [TxnType.SEND, TxnType.RECEIVE].includes(this.type as TxnType);
  }

  isTradeType() {
    return [
      TxnType.TRADE,
      TxnType.BUY,
      TxnType.SELL,
      TxnType.ADVANCED_TRADE_FILL,
      TxnType.ADVANCED_TRADE_ORDER,
    ].includes(this.type as TxnType);
  }

  isWithdrawal() {
    return this.type === TxnType.WITHDRAWAL;
  }

  isDeposit() {
    return this.type === TxnType.DEPOSIT;
  }

  isBuy() {
    return this.type === TxnType.BUY;
  }

  isSell() {
    return this.type === TxnType.SELL;
  }
  // Display rules for table columns
  getDisplayData() {
    const baseData = {
      transaction: this.tradeid ? `${this.tradeid.substring(0, 5)}...` : "-",
      type: this.formattedType(),
      side: this.getFormattedSide(),
      buyAsset: this.buyasset ?? "-",
      sellAsset: this.sellasset ?? "-",
      buyAmount: this.buyqty ? `+${formatAmount(this.buyqty)}` : "-",
      sellAmount: this.sellqty ? `-${formatAmount(this.sellqty)}` : "-",
      dateUTC: this.formattedDate(),
      exchangeName: this.exchange?.exchangename,
      formattedBuyAmount: this.buyqty
        ? `+${formatAmount(this.buyqty)} ${this.buyasset}`
        : "-",
      formattedSellAmount: this.sellqty
        ? `-${formatAmount(this.sellqty)} ${this.sellasset}`
        : "-",
      /* this will show the asset for either buy or sell or withdrawal */
      asset: this.getAsset(),
      /* shows either Buy Sell, Deposit, Withdrawal */
      formattedType: this.formattedType(),
    };

    // Adjust data based on the category
    switch (this.category) {
      case "tradeType":
        return baseData;
      case "transferType":
        return {
          ...baseData,
          side: "-", // Side not relevant for transfers
        };
      case "blockchainTransferType":
        return {
          ...baseData,
          side: "-", // Side not relevant for transfers
        };
      case "interestType":
        return {
          ...baseData,
          side: "-", // Side not relevant
          sellAsset: "-", // No sell asset
          sellAmount: "-", // No sell amount
          formattedSellAmount: "-", // No formatted sell amount
        };
      default: // Unknown type
        return {
          ...baseData,
          type: "Unknown",
          side: "-",
        };
    }
  }

  // Formatting helpers
  formattedType() {
    switch (this.type) {
      case TxnType.WITHDRAWAL:
        return "Withdrawal";
      case TxnType.DEPOSIT:
        return "Deposit";
      case TxnType.BUY:
        return "Buy";
      case TxnType.SELL:
        return "Sell";
      case TxnType.TRADE:
        return "Trade";
      case TxnType.INTEREST:
        return "Interest";
      case TxnType.SEND:
        return "Send";
      case TxnType.RECEIVE:
        return "Receive";
      default:
        return this.type
          ?.split(" ")
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(" ");
    }
  }

  formattedDate() {
    return this.datetime
      ? dayjs(this.datetime).utc().format("MMM D YYYY")
      : "-";
  }

  getFormattedSide() {
    return this.side === "buy" ? "Buy" : this.side === "sell" ? "Sell" : "-";
  }

  // Individual data accessors
  getTransactionType() {
    return this.formattedType();
  }

  getBuyAmount() {
    return this.buyqty ? `+${this.buyqty}` : "-";
  }

  getSellAmount() {
    return this.sellqty ? `-${this.sellqty}` : "-";
  }

  getDate() {
    return this.formattedDate();
  }

  getSide() {
    return this.getFormattedSide();
  }

  getAssetPair() {
    if (this.side === "sell") {
      return `${this.sellasset?.toUpperCase()}/${this.buyasset?.toUpperCase()}`;
    } else if (this.side === "buy") {
      return `${this.buyasset?.toUpperCase()}/${this.sellasset?.toUpperCase()}`;
    } else {
      return "Unknown Pair";
    }
  }

  formattedBuyAmountWithCurrency() {
    const amount = this.getFormattedAmount();
    const asset = this.getAsset();
    if (amount && asset) {
      return `${this.getFormattedAmount()} ${this.getAsset()}`;
    } else {
      return "";
    }
  }

  formattedSellAmountWithCurrency() {
    const counterAmount = this.getCounterAmount();
    const counterAsset = this.getCounterAsset();
    // console.log("counterAmount", counterAmount);
    // console.log("counterAsset", counterAsset);
    if (counterAmount && counterAsset) {
      return `${this.getCounterAmount()} ${this.getCounterAsset()}`;
    } else {
      return "";
    }
  }
  formattedFeeQty() {
    // Add your implementation here

    return formatAmount(this.feeqty);
  }
  formattedFiatValue() {
    // Add your implementation here

    return formatAmount(this.final_value);
  }

  formattedFiatFeeValue() {
    // Add your implementation here
    return formatAmount(this.final_feevalue);
  }

  getMissingFiatValueProperties() {
    let errorMessage = "";

    const missingValues = [];
    if (this.missing_final_feevalue && this.final_feevalue !== 0) {
      missingValues.push("Fiat fee value");
    }

    // Only check for missing_final_value if isTransferType is not true
    if (
      // !this.isTransferType &&
      this.missing_final_value &&
      this.final_value !== 0
    ) {
      missingValues.push("Fiat transaction value");
    }

    if (missingValues.length > 0) {
      errorMessage = missingValues.join(" AND ") + " is missing.";
    }

    if (errorMessage) {
      return errorMessage;
    } else {
      return false;
    }
  }
  /**
   * this checks the missing_final_value and missing_final_feevalue fields on the SQL that denote
   * if the final_value and final_feevalue are missing
   */
  getMissingKeyProperties() {
    const missingKeys = [];

    if (this.missing_final_feevalue || this.final_feevalue === 0) {
      missingKeys.push("final_feevalue");
    }

    // Only check for missing_final_value if isTransferType is not true
    if (
      !this.isTransferType &&
      this.missing_final_value &&
      this.final_value === 0
    ) {
      missingKeys.push("final_value");
    }

    return missingKeys;
  }

  truncate(input, nchars) {
    if (input.length > nchars) {
      return input.substring(0, nchars) + "...";
    }
    return input;
  }
}
