import React, { useCallback, useState } from "react";
import { Exchange, useOnboardingActor } from "@moonscape/shared";
import {
  ArrowLeftIcon,
  CloudArrowUpIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useDropzone } from "react-dropzone";
import { useUploadFile } from "@/screens/TradesScreen/useUploadFile";

interface CSVUploadScreenProps {
  exchange: Exchange;
  onBack: () => void;
  onSubmit: (file: File) => Promise<void>;
}

/**
 * CSVUploadScreen - the component itself handles the file upload and import
 * @param exchange
 * @param onBack
 * @returns
 */
export const CSVUploadScreen = ({ exchange, onBack }: CSVUploadScreenProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { importFile, isLoading: isUploading } = useUploadFile();
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);

  const showRegisterInterest = !exchange.csv_active;

  const actor = useOnboardingActor();
  const { send } = actor;

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file?.type !== "text/csv" && !file?.name.endsWith(".csv")) {
      setError("Please upload a CSV file");
      return;
    }
    setSelectedFile(file);
    setError(null);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
    },
    maxFiles: 1,
  });

  const handleSubmit = async () => {
    if (!selectedFile) return;

    try {
      setError(null);
      await importFile(selectedFile);
      setUploadSuccess(true);
      send({
        type: "EXCHANGE_CONNECTED",
        data: { exchangeName: exchange?.exchangename, isCSV: true },
      });
    } catch (err) {
      console.log("CSVUploadScreen error: ", err);
      setError("Failed to upload file. Please try again.");
    }
  };

  return (
    <div className="space-y-4">
      {/* Header */}
      <button
        onClick={onBack}
        className="flex items-center space-x-3 mb-6 text-gray-500 hover:text-gray-700 group"
      >
        <ArrowLeftIcon className="h-5 w-5" />
        <div className="flex items-center">
          <img
            src={exchange?.thumbnail_url}
            alt={exchange?.name}
            className="w-8 h-8 object-contain mr-2"
          />
          <h3 className="text-lg font-medium">
            Upload CSV for {exchange?.exchangename}
          </h3>
        </div>
      </button>

      {/* Upload Area */}
      <div
        {...getRootProps()}
        className={`
          border-2 border-dashed rounded-lg p-8
          ${isDragActive ? "border-cyan-500 bg-cyan-50" : "border-gray-300"}
          ${selectedFile ? "border-cyan-500 bg-cyan-50" : ""}
          transition-colors duration-200
        `}
      >
        <input {...getInputProps()} />

        {selectedFile ? (
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <CloudArrowUpIcon className="h-6 w-6 text-cyan-600" />
              <span className="text-sm text-gray-600">{selectedFile.name}</span>
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setSelectedFile(null);
              }}
              className="text-gray-400 hover:text-gray-600"
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>
        ) : (
          <div className="text-center">
            <CloudArrowUpIcon className="mx-auto h-12 w-12 text-gray-400" />
            <div className="mt-4">
              <p className="text-sm text-gray-600">
                {isDragActive ? (
                  "Drop the CSV file here"
                ) : (
                  <>
                    Drag and drop your CSV file here, or{" "}
                    <span className="text-cyan-600">browse</span>
                  </>
                )}
              </p>
              <p className="text-xs text-gray-500 mt-2">
                Only CSV files are supported
              </p>
            </div>
          </div>
        )}
      </div>

      {/* Format Help Section */}
      <div className="bg-gray-50 rounded-lg p-4 space-y-3">
        <h4 className="font-medium text-gray-900">Supported Formats</h4>
        <div className="space-y-2 text-sm text-gray-600">
          <p>We support the following CSV formats:</p>
          <ul className="list-disc list-inside space-y-1 ml-2">
            {!showRegisterInterest && (
              <li>{exchange?.exchangename} export format</li>
            )}
            <li>Moonscape CSV format</li>
          </ul>
          <div className="flex items-center space-x-2 mt-4">
            <a
              href={`https://moonscape.app/download/moonscape-sample.csv`}
              download
              className="text-cyan-600 hover:text-cyan-700 flex items-center"
            >
              <CloudArrowUpIcon className="h-4 w-4 mr-1" />
              Download sample Moonscape CSV file
            </a>
            {/* <span className="text-gray-400">•</span>
            <a
              href="https://moonscape.app/download/moonscape-sample.csv"
              target="_blank"
              rel="noopener noreferrer"
              className="text-cyan-600 hover:text-cyan-700"
            >
              View format documentation
            </a> */}
          </div>
        </div>
      </div>

      {/* Error Message */}
      {error && <div className="text-sm text-red-600 mt-2">{error}</div>}

      {/* Action Buttons */}
      <div className="mt-6 flex justify-end space-x-3">
        <button
          type="button"
          onClick={onBack}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Back
        </button>
        <button
          onClick={handleSubmit}
          disabled={!selectedFile || isUploading}
          className={`
            px-4 py-2 text-sm font-medium text-white rounded-md
            ${
              uploadSuccess
                ? "bg-green-400 text-green-600 cursor-default"
                : !selectedFile || isUploading
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-cyan-600 hover:bg-cyan-700"
            }
          `}
        >
          {uploadSuccess
            ? "Upload successful"
            : isUploading
            ? "Uploading..."
            : "Upload CSV"}
        </button>
      </div>
    </div>
  );
};
