import React from "react";
import { Formik, Field } from "formik";
import { Exchange } from "@moonscape/shared";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

interface APIFormScreenProps {
  exchange: Exchange;
  onBack: () => void;
  onSubmit: (values: any) => Promise<void>;
}

export const APIFormScreen = ({
  exchange,
  onBack,
  onSubmit,
}: APIFormScreenProps) => {
  const inputBaseClasses =
    "mt-1 block w-full rounded-lg border border-gray-300 shadow-sm py-4 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm";

  // Form validation functions
  const validateField = (value: string) =>
    value ? undefined : "This field cannot be empty";

  const initialValues = {
    exchange: exchange.id,
    apiKey: "",
    apiSecret: "",
    apiPassword: "",
  };

  const showRegisterInterest = !exchange.api_active && !exchange.csv_active;

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-3 mb-6">
        <button onClick={onBack} className="text-gray-500 hover:text-gray-700">
          <ArrowLeftIcon className="h-5 w-5" />
        </button>
        <div className="flex items-center">
          <img
            src={exchange?.thumbnail_url}
            alt={exchange?.name}
            className="w-8 h-8 object-contain mr-2"
          />
          <h3 className="text-lg font-medium">
            Connect {exchange?.exchangename}
          </h3>
        </div>
      </div>

      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit, errors, touched, isSubmitting }) => (
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* API Key - Always required */}
            <div className="space-y-2">
              <Field
                type="text"
                name="apiKey"
                id="apiKey"
                placeholder="Enter API key"
                validate={validateField}
                className={inputBaseClasses}
              />
              {errors.apiKey && touched.apiKey && (
                <p className="text-sm text-red-600">{errors.apiKey}</p>
              )}
            </div>

            {/* API Secret - if required */}
            {exchange.api_required_fields.includes("secret") && (
              <div className="space-y-2">
                <Field
                  type="text"
                  name="apiSecret"
                  id="apiSecret"
                  placeholder="Enter API secret"
                  validate={validateField}
                  className={inputBaseClasses}
                />
                {errors.apiSecret && touched.apiSecret && (
                  <p className="text-sm text-red-600">{errors.apiSecret}</p>
                )}
              </div>
            )}

            {/* API Password/Passphrase - if required */}
            {exchange.api_required_fields.includes("password") && (
              <div className="space-y-2">
                <Field
                  type="text"
                  name="apiPassword"
                  id="apiPassword"
                  placeholder="Enter API Passphrase"
                  validate={validateField}
                  className={inputBaseClasses}
                />
                {errors.apiPassword && touched.apiPassword && (
                  <p className="text-sm text-red-600">{errors.apiPassword}</p>
                )}
              </div>
            )}

            <div className="mt-6 flex justify-end space-x-3">
              <button
                type="button"
                onClick={onBack}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Back
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className={clsx(
                  "px-4 py-2 text-sm font-medium text-white rounded-md",
                  isSubmitting ? "bg-gray-400" : "bg-cyan-600 hover:bg-cyan-700"
                )}
              >
                {isSubmitting ? "Connecting..." : "Connect Exchange"}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
