import React, { useState, useEffect } from "react";
import { Slide } from "./Slide";
import { useOnboardingActor } from "@moonscape/shared";
import { useSelector } from "@xstate/react";
import {
  SelectionComponent,
  TaxSettingKey,
  taxSettingsOptions,
  TaxJurisdictionSelect,
} from "@/components";
import { TaxSettings } from "../SettingsScreen/TaxSettings";

// ============================================
// Types
// ============================================

interface TaxSettingsState {
  jurisdiction: string;
  accountingMethod?: string;
  filingStatus?: string;
  cgtRate?: string;
}

interface TaxSettingsValidation {
  isComplete: boolean;
  requiredFields: string[];
}

// ============================================
// Component
// ============================================

export const OnboardingTaxSettings = () => {
  const actor = useOnboardingActor();
  const { send } = actor;
  const settings = useSelector(actor, (state) => ({
    jurisdiction: state.context.taxJurisdiction,
  }));

  // Render helpers
  const renderTaxSettings = () => {
    return <TaxSettings />;
  };

  return (
    <Slide
      heading="Set up your tax profile"
      description={
        <div className="space-y-6">
          <p className="text-lg text-center text-gray-700">
            We'll use this information to calculate your taxes correctly.
          </p>
          {renderTaxSettings()}
        </div>
      }
      onBack={() => send({ type: "BACK" })}
      primaryButton={{
        text: "Continue",
        onPress: () => {
          send({
            type: "NEXT",
          });
        },
      }}
    />
  );
};
