import dayjs from "dayjs";

import { formatAmount } from "@moonscape/shared";

import { TaxEvent } from "@moonscape/shared";

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

/**
 * utility class that gives helpful functions to format a TaxEvent
 */
export class TaxEventItemClass {
  // class property types
  txn_id: string;
  disposal_type: string;
  asset: string;
  tax_year: string;
  proceeds: string;
  gain: string;
  cost: string;
  fees: string;
  quantity: string;
  datetime: string;

  constructor(taxEventItem: TaxEvent) {
    const {
      txn_id,
      disposal_type,
      asset,
      tax_year,
      proceeds,
      gain,
      cost,
      fees,
      quantity,
      datetime,
    } = taxEventItem;

    this.txn_id = txn_id;
    this.disposal_type = disposal_type;
    this.asset = asset;
    this.tax_year = tax_year;
    this.proceeds = proceeds;
    this.gain = gain;
    this.cost = cost;
    this.fees = fees;
    this.quantity = quantity;
    this.datetime = datetime;
  }

  getFormattedQuantity() {
    return formatAmount(this.quantity);
  }

  formattedTimestamp() {
    if (this.datetime !== "") {
      return dayjs(this.datetime).utc().format("MMM D YYYY, HH:mm:ss");
    } else {
      return "";
    }
  }

  truncate(input, nchars) {
    if (input?.length > nchars) {
      return input.substring(0, nchars) + "...";
    }
    return input;
  }
}
